import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Divider,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import {
  useCreateCreditNoteMutation,
  useSearchSupplierQuery,
  useUpdateCreditNoteMutation,
} from '../../../../store/slices/logisticsSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { GreenButton } from '../../style';
import { button, buttonBlock } from '../style';
import { defaultValues, schema } from './creditNoteSchema';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import DatePicker from '../../../../form/components/DatePicker';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import { getOptions } from '../../../../utils/getOptions';
import { useGetCurrencyQuery } from '../../../../store/session';

const CreditNoteForm = ({
  type, drawerClose, handleRefresh, creditNote = null, supplier_id = null
}) => {
  const [supplier, setSupplier] = useState({});
  const update = type === 'update';

  const { data: currencies } = useGetCurrencyQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const currencyOptions = getOptions(currencies);

  const reasonOptions = [
    { label: 'Product not delivered but paid', value: 'Product not delivered but paid' },
    { label: 'Incorrect or faulty product supplied', value: 'Incorrect or faulty product supplied' },
    { label: 'Overbilling or invoice error', value: 'Overbilling or invoice error' },
  ];

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: update ? creditNote : defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [sendCreditNote] = useCreateCreditNoteMutation();
  const [updateCreditNote] = useUpdateCreditNoteMutation();
  const handleError = useHandleQueryFormError();

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const res = update ? await updateCreditNote({ id: creditNote?.id, data }) : await sendCreditNote({ data });

    if (res.error) {
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    toast.success(`The Credit Note was ${update ? 'updated' : 'created'}!`, {
      id: toastId,
    });

    if (!update) {
      drawerClose();
      reset();
    }

    handleRefresh();
  };

  useEffect(() => {
    if (type === 'update') {
      Object.keys(defaultValues).forEach((key) => setValue(key, creditNote?.[key]));
    }
    setValue('type', type);

    if (supplier_id) {
      setValue('logistic_supplier_id', supplier_id);
    }
  }, [creditNote, supplier_id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ padding: '0 1rem', mt: 5 }}>
        {!supplier_id && (
          <Grid item xs={12} md={6}>
            <FormGroup label="Supplier">
              <Controller
                name="logistic_supplier_id"
                control={control}
                render={({ field }) => (
                  <ServerAutocomplete
                    field={field}
                    value={supplier}
                    withData
                    placeholder="Search suppliers by name or email"
                    searchQueryFn={useSearchSupplierQuery}
                    queryParams={{ type: 'service' }}
                    handleSelect={(value) => {
                      field.onChange(value?.value);
                      setSupplier(value);
                      setValue('logistic_supplier_id', value?.value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormGroup
            label="Amount"
            required
            hasError={!!errors.value}
          >
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="small"
                  placeholder="Enter Amount"
                  InputProps={{
                    inputProps: { min: 0, step: '0.01' },
                  }}
                  error={!!errors.value}
                  helperText={errors.value ? errors.value.message : ''}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup
            label="Currency"
            required
            hasError={!!errors.currency_id}
          >
            <Controller
              name="currency_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={currencyOptions.find((option) => option.value === field.value) || null}
                  options={currencyOptions}
                  size="small"
                  placeholder="Select Currency"
                  onChange={(e, value) => {
                    field.onChange(value?.value);
                    setValue('currency_id', value?.value || null);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup
            label="Date"
            required
            hasError={!!errors.date}
          >
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  placeholderText="YYYY-MM-DD"
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => {
                    field.onChange(e);
                    setDateValue(e, setValue, 'date', 'YYYY-MM-DD');
                  }}
                  selected={validateDatePickerValue(field.value)}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <FormGroup
            label="Description"
            hasError={!!errors.description}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Description"
                  error={!!errors.description}
                  helperText={errors.description ? errors.description.message : ''}
                />
              )}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <FormGroup
            label="Reason"
            hasError={!!errors.reason}
          >
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={reasonOptions.find((option) => option.value === field.value) || null}
                  options={reasonOptions}
                  size="small"
                  placeholder="Select Reason"
                  onChange={(e, value) => {
                    field.onChange(value?.value);
                    setValue('reason', value?.value || null);
                  }}
                />
              )}
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Box sx={{
        ...buttonBlock, textAlignLast: 'start', padding: '0 1rem', mt: 3
      }}
      >
        <Button sx={{ ...button, ...(update && GreenButton) }} title={update ? 'Save' : 'Create'} type="submit" />
      </Box>
    </form>
  );
};

export default CreditNoteForm;
