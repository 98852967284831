import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, CardContent, CardHeader, Switch,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '../../../../../../../components/Button';
import Upload from '../../../../../../../components/Upload';
import Autocomplete from '../../../../../../../form/components/Autocomplete/Autocomplete';
import FieldError from '../../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../../form/components/FormGroup';
import {
  useGetCurrencyQuery,
} from '../../../../../../../store/session';
import { useGetOrdersListQuery, usePostPaymentRequestMutation } from '../../../../../../../store/slices/logisticsSlice';

import ContentPageLoader from '../../../../../../../components/Loader/ContentPageLoader';
import DatePicker from '../../../../../../../form/components/DatePicker';
import Input from '../../../../../../../form/components/Input';
import { useGetDeliveryOrdersListQuery } from '../../../../../../../store/slices/internalLogisticsSlice';
import { getOptions } from '../../../../../../../utils/getOptions';
import { setDateValue, validateDatePickerValue } from '../../../../../../../utils/setDateValue';
import { defaultValues, schema } from './schema';
import {
  button, buttonBlock,
  block as currentBlock,
  documentForm, file, fileName, label
} from './style';
import { useGetProformaListQuery } from '../../../../../../../store/slices/invoiceManagementSlice';

export const orderMathRoundSum = (count) => {
  if (count < 1) return 1;

  return Math.round(count);
};

export const countInvoiceSum = (array = []) => {
  let sum = 0;

  array.forEach(({ part_count, part_price }) => {
    const count = orderMathRoundSum(part_count);
    const orderSum = count * +part_price;

    sum += orderSum;
  });

  return sum;
};

export const defaultOrder = (od, typeBoard) => ({
  currency_id: od?.prices?.[0]?.currency_id,
  label: `Order #${(typeBoard === 'orders') ? od?.id : `${od?.id}D`}`,
  part_count: orderMathRoundSum(od?.part_count),
  part_price: od?.prices?.[0]?.amount,
  value: od?.id
});

const InvoicesForm = ({
  order, variant, drawerClose, dashboardType = 'orders'
}) => {
  const [nameFile, setNameFile] = useState();

  const [selectOrders, setSelectOrders] = useState(order ? [defaultOrder(order, dashboardType)] : []);
  const { data: currency } = useGetCurrencyQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const { data: currentOrdersList, isLoading: isLoadingCurrentOrders } = useGetOrdersListQuery({ variant: 'part' }, {
    refetchOnMountOrArgChange: true,
    skip: dashboardType === 'delivery'
  });

  const { data: internalOrdersList, isLoading: isLoadingInternalOrders } = useGetDeliveryOrdersListQuery({ variant: order ? 'delivery' : variant }, {
    refetchOnMountOrArgChange: true,
    skip: dashboardType === 'orders'
  });

  const { data: proforma } = useGetProformaListQuery({
    refetchOnMountOrArgChange: true
  });

  const isLoadingOrders = (dashboardType === 'orders') ? isLoadingCurrentOrders : isLoadingInternalOrders;
  const ordersList = (dashboardType === 'orders') ? currentOrdersList : internalOrdersList;
  const checkCurrentOrder = (ordersList || []).some(item => item?.value === order?.id);
  const [sendInvoice] = usePostPaymentRequestMutation();
  const block = order ? currentBlock : { ...currentBlock, mb: '15px' };
  const currencyOptions = getOptions(currency);
  const proformaOptions = getOptions(proforma);

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const ordersIds = data.orders.map(({ value }) => value);
    const formData = new FormData();

    formData.append('description', data.description);
    formData.append('file', data.file);
    formData.append('invoice_sum', data.invoice_sum);
    formData.append('currency_id', data.currency_id);
    formData.append('payment_type', variant);
    formData.append('due_payment_date', data.due_payment_date);
    formData.append('orders', JSON.stringify(ordersIds));
    formData.append('is_proforma', data.is_proforma ? 1 : 0);

    if (dashboardType !== 'orders') {
      formData.append('is_internal', true);
    }

    if (data.parent_id?.value && !data.is_proforma) {
      formData.append('parent_id', data.parent_id?.value);
    }

    const res = await sendInvoice({ data: formData });

    if (res.error) {
      toast.error(res.error.data?.message, {
        id: toastId,
      });

      return;
    }

    toast.success('The invoice has been sent!', {
      id: toastId,
    });

    reset();

    setValue('payment_purpose', {});
    setValue('currency', {});
    setValue('parent_id', {});
    setValue('payment_purpose_id', {});
    setValue('currency_id', {});
    setValue('orders', (order && checkCurrentOrder) ? [defaultOrder(order, dashboardType)] : []);
    document.getElementById('file-upload').value = '';
    setNameFile(null);

    if (!order) drawerClose();
  };

  const changeDescription = (checkedOrders = []) => {
    let decr = 'Order ID: ';

    checkedOrders.forEach((item) => {
      decr += ` #${(dashboardType === 'orders') ? item.value : `${item.value}D`},`;
    });

    return decr;
  };

  const generateDescription = () => `Order ID:  ${order ? `#${(dashboardType === 'orders') ? order?.id : `${order?.id}D`},` : ''}\n`;

  useEffect(() => {
    if (currency) {
      if (order && variant === 'part') {
        setValue('invoice_sum', ((order?.prices?.[0]?.amount || 0) * orderMathRoundSum(order?.part_count)));
        setValue('currency', currencyOptions?.filter((item) => item.value == order?.prices?.[0]?.currency_id)[0] || '');
        setValue('currency_id', order?.prices?.[0]?.currency_id);
      }

      if (order) setValue('orders', (order && checkCurrentOrder) ? [defaultOrder(order, dashboardType)] : []);
      setValue('description', generateDescription());
    }
    if (ordersList && !checkCurrentOrder) setSelectOrders([]);
  }, [currency, ordersList, checkCurrentOrder, dashboardType, order]);

  const changeOrders = (value, field) => {
    field.onChange(value);
    setValue('orders', value);
    setValue('description', changeDescription(value));
    if (variant === 'part') setValue('invoice_sum', countInvoiceSum(value));
    trigger('orders', value);
    setSelectOrders(value);
  };

  if (isLoadingOrders) {
    return (
      <Card>
        <ContentPageLoader />
      </Card>
    );
  }

  return (
    <Card sx={{ mt: order ? 5 : 0, border: order ? '1px solid whitesmoke' : 'none' }}>
      {!!order && <CardHeader title={`Create New ${variant} Invoice Request`} sx={{ textTransform: 'capitalize' }} />}
      <CardContent sx={{ mt: order ? 5 : 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={documentForm}>

            <Box sx={file}>
              <FormGroup sx={label} required label="File" hasError={!!errors.file}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      accept=".doc,.docx,image/*,.pdf"
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFile(e);
                      }}
                      title="Upload file"
                    />
                  )}
                />
                <FieldError error={errors.file} />
              </FormGroup>
              <Typography sx={fileName}>{nameFile}</Typography>
            </Box>

            {variant !== 'delivery' && (
              <Box sx={block}>
                <FormGroup label="Proforma" hasError={!!errors?.is_proforma}>
                  <Controller
                    name="is_proforma"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        checked={field.value}
                        onChange={() => {
                          setValue('is_proforma', !watch('is_proforma'));
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.is_proforma} />
                </FormGroup>
              </Box>
            )}

            <Box sx={block}>
              <FormGroup sx={label} label="Description" required hasError={!!errors.description}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      rows={8}
                      multiline
                      variant="filled"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.description} />
              </FormGroup>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} label="Deadline Payment" required hasError={!!errors.due_payment_date}>
                <Controller
                  name="due_payment_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="YYYY-MM-DD"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setDateValue(e, setValue, 'due_payment_date', 'YYYY-MM-DD');
                      }}
                      value={validateDatePickerValue(field.value)}
                    />
                  )}
                />
                <FieldError error={errors.due_payment_date} />
              </FormGroup>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} label="SUM" required hasError={!!errors.invoice_sum}>
                <Controller
                  name="invoice_sum"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
                <FieldError error={errors.invoice_sum} />
              </FormGroup>
            </Box>

            <Box sx={block}>
              <FormGroup sx={label} required label="Currency" hasError={!!errors.currency}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={field.value ? currencyOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                      options={currencyOptions}
                      size="small"
                      placeholder="Please select a currency"
                      onChange={(e, value ) => {
                        field.onChange(value);
                        setValue('currency', value || null);
                        setValue('currency_id', value?.value || null);
                        trigger('currency', value);
                      }}
                    />
                  )}
                />
                <FieldError error={errors.currency} />
              </FormGroup>
            </Box>

            {(!watch('is_proforma') && variant !== 'delivery') && (
              <Box sx={block}>
                <FormGroup sx={label} label="Proforma" hasError={!!errors.parent_id}>
                  <Controller
                    name="parent_id"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value ? proformaOptions?.filter((item) => item.value === field?.value?.value)[0] : null}
                        options={proformaOptions}
                        size="small"
                        placeholder="Select a proforma"
                        onChange={(e, value ) => {
                          field.onChange(e);
                          setValue('parent_id', value);
                        }}
                      />
                    )}
                  />
                  <FieldError error={errors.parent_id} />
                </FormGroup>
              </Box>
            )}

            <Box sx={block}>
              <FormGroup sx={label} label="Attach other orders" hasError={!!errors.orders}>
                <Controller
                  name="orders"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      value={selectOrders}
                      options={[...(ordersList || [])]}
                      size="small"
                      multiple
                      placeholder=""
                      onChange={(e, value ) => {
                        const isSome = order && value.some(od => od?.value
                          === order?.id);

                        if (isSome || !order) {
                          changeOrders(value, field);
                        } else if (!isSome) {
                          const newValue = checkCurrentOrder ? [defaultOrder(order, dashboardType), ...value] : value;
                          changeOrders(newValue, field);
                        }
                      }}
                    />
                  )}
                />
                <FieldError error={errors.orders} />
              </FormGroup>
            </Box>

            <Box sx={{ ...buttonBlock, textAlignLast: order ? 'center' : 'start' }}>
              <Button sx={{ ...button, ...(!order && { mt: 2 }) }} title={order ? 'Send' : 'Create'} type="submit" />
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default InvoicesForm;
