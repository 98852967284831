import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Chip from '@mui/material/Chip';
import FiltrationInput from '../../../../components/EditableTable/FiltrationInput';
import { HeaderName } from '../../../../components/EditableTable/styles';

export const columns = ({
  handleFilter,
  filters,
}) => ([
  {
    flex: 0.3,
    field: 'value',
    minWidth: 160,
    headerName: 'Amount',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Amount</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {row?.value}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'currency_id',
    minWidth: 160,
    headerName: 'Currency',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Currency</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {row?.currency_id ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.3,
    field: 'date',
    minWidth: 180,
    headerName: 'Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', fontWeight: '600' }}
      >
        {row?.date ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    field: 'description',
    minWidth: 180,
    headerName: 'Description',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Description</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all',
        }}
      >
        {row?.description ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.4,
    field: 'reason',
    minWidth: 200,
    headerName: 'Reason',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Reason</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip
        label={row?.reason ?? 'N/A'}
        variant="outlined"
        sx={{
          textTransform: 'capitalize',
          fontSize: '0.875rem',
          fontWeight: '500',
        }}
      />
    ),
  }
]);
