import React from 'react';
import {
  Card, CardContent, CardHeader, Grid
} from '@mui/material';
import FormGroup from '../../../../form/components/FormGroup/FormGroup';
import FieldError from '../../../../form/components/FieldError';
import CurrencyInput from '../../../../form/components/CurrencyInput';

const ConditionsForm = (props) => {
  const {
    control, Controller, errors,
  } = props;

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Conditions" />
      <CardContent>
        <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={12}>
            <FormGroup label="Acmi" hasError={!!errors.fleet_acmi}>
              <Controller
                name="fleet_acmi"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    placeholder="Acmi"
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_acmi} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormGroup label="Nav/1nm" hasError={!!errors.fleet_cond_nav}>
              <Controller
                name="fleet_cond_nav"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    placeholder="Nav/1nm"
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_cond_nav} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormGroup label="Profit" hasError={!!errors.fleet_cond_other}>
              <Controller
                name="fleet_cond_other"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    placeholder="Profit"
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_cond_other} />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormGroup label="Total Fuel Price" hasError={!!errors.total_fuel_price}>
              <Controller
                name="total_fuel_price"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    placeholder="Total Fule Price"
                    readOnly
                    {...field}
                  />
                )}
              />

              <FieldError error={errors.fleet_cond_fuel} />
            </FormGroup>
          </Grid>

        </Grid>
      </CardContent>

    </Card>
  );
};

export default ConditionsForm;
