import { createSlice } from '@reduxjs/toolkit';
import { sessionApi } from '../session';
import { useProvideTags } from '../../utils/hooks/useProvideTags';

export const invoiceManagementSlice = createSlice({
  name: 'invoice_management',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getBoardData: builder.query({
      query: (params) => `/finance/invoice_management/board${params}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_BOARD']),
    }),
    getInvoiceDetails: builder.query({
      query: ({ id }) => `/finance/invoice_management/get-invoice/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_INVOICE']),
    }),
    setInvoiceArchive: builder.mutation({
      query: (id) => ({
        url: `/finance/invoice_management/set-archive/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    approvePayment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/invoice_management/approve/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getProformaList: builder.query({
      query: ({ id }) =>'/finance/invoice_management/proforma_list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetBoardDataQuery,
  useGetInvoiceDetailsQuery,
  useSetInvoiceArchiveMutation,
  useApprovePaymentMutation,
  useGetProformaListQuery
} = sessionApi;

export default invoiceManagementSlice.reducer;
