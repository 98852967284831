import React from 'react';
import { Card } from '@mui/material';
import CreditNoteForm from './CreditNoteForm';
import EditableTable from '../../../../components/EditableTable';
import { useGetCreditNotesQuery } from '../../../../store/slices/logisticsSlice';
import { columns } from './columns';

const CreditNotes = ({ supplier = null }) => (
  <>
    <Card sx={{ mt: 3, padding: '1rem' }}>
      <CreditNoteForm type="create" drawerClose={() => {}} handleRefresh={() => {}} supplier_id={supplier?.id} />
    </Card>

    <Card sx={{ mt: 3, padding: '1rem' }}>
      <EditableTable
        useHook={useGetCreditNotesQuery}
        getRowId={(row) => row.id}
        columns={columns}
        sortByType="DESC"
        sortByActive="id"
        getRowHeight={() => 'auto'}
        tableParams={{
          'logistic_supplier_id': supplier?.id
        }}
      />
    </Card>
  </>
);

export default CreditNotes;
