import {
  Box, Card, Divider, Typography, useMediaQuery
} from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import EditableTable from '../../../components/EditableTable';
import LinkButton from '../../../components/LinkButton';
import {
  useCreateInstructorMutation,
  useDeleteInstructorRowMutation,
  useGetInstructorTableQuery,
  useUpdateInstructorTableMutation
} from '../../../store/session';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import InstructorForm from './InstructorForm/InstructorForm';
import { columns } from './columns';
import {
  btnS,
  headerBtn,
  headerBtnS,
  headerBtnXs,
  tableContainer,
} from './styles';

const getInsturctorStatus = (instructor) => {
  let res = '';
  if (instructor) {
    if (instructor?.docs_empty) {
      res = 'gray';

      return res;
    } 
    if (instructor?.docs_expired) {
      res = 'red';

      return res;
    } 

    res = 'green';

    return res;
  }

  return res;
};

const TrainingPersonnel = () => {
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const [openDrawer, setOpenDrawer] = useState(false);

  const [createInstructor] = useCreateInstructorMutation();

  const hasPermissions = usePermissions('training_module_write');

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const res = await createInstructor(data);
    const error = res?.error?.data?.message;

    if (error) {
      toast.error(error, {
        id: toastId,
      });

      return;
    }
    toast.success('Success!', {
      id: toastId,
    });
    setOpenDrawer(false);
  };

  return (
    <>
      <LinkButton path="/training-module">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
        >
          <Box>
            <Typography variant="h3">Training Personnel</Typography>
          </Box>

          <Box sx={{ ...(maxWidth800px && headerBtnXs) }}>
            <Button
              sx={{ ml: 2, ...(maxWidth800px && btnS) }}
              disabled={!hasPermissions}
              onClick={() => setOpenDrawer(true)}
              title="Create New"
            />
          </Box>
        </Box>

        <Divider sx={{ mt: 5, mb: 3 }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              width: maxWidth800px ? '95%' : '60%',
              ...tableContainer
            }}
          >
            <EditableTable
              useHook={useGetInstructorTableQuery}
              useHookDelete={useDeleteInstructorRowMutation}
              useHookUpdate={useUpdateInstructorTableMutation}
              getRowId={(row) => row.id}
              columns={columns}
              hasPermissions={hasPermissions}
              sortByType="ASC"
              getRowClassName={({ row }) => `row-${getInsturctorStatus(row)}`}
              sortByActive="id"
            />
          </Box>
        </Box>
      </Card>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          ...(maxWidth800px && {
            '& .MuiPaper-elevation': {
              width: '100vw'
            },
            '& .MuiPaper-rounded': {
              width: 'auto'
            }
          })
        }}
      >
        <InstructorForm onSubmit={onSubmit} maxWidth800px={maxWidth800px} />
      </Drawer>
    </>
  );
};

export default TrainingPersonnel;
