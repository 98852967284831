import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { encodeObjectToURIParams } from '../utils/helper';
import { useProvideTags } from '../utils/hooks/useProvideTags';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: (headers, { getState }) => {
    if (getState()?.session?.adminToken) {
      headers.set('authorization', `Bearer ${getState().session.adminToken}`);
      headers.set('Accept', 'application/json');
    }

    return headers;
  },
});

const handleBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    // eslint-disable-next-line no-use-before-define
    api.dispatch(removeAdminToken());
  }

  return result;
};

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: handleBaseQuery,
  tagTypes: [
    'UNAUTHORIZED',
    'USER_DOCUMENTS',
    'WO_FILES',
    'WO_PARTS_PAIR',
    'CONSUMABLE',
    'TASK_TOOLS',
    'ODS_DEFECT',
    'WO_UPDATED',
    'DEPARTMENT_UPDATED',
    'CURRENCY_UPDATED',
    'PURPOSES_UPDATED',
    'TRAINING_SCHEDULE_UPDATED',
    'INSTRUCTORS_UPDATED',
    'WORK_ORDER_FILES',
    'SIGNATURES_TABLE'
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/login',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['UNAUTHORIZED'] : []),
    }),
    getFlightsList: builder.query({
      query: (arg) => `/flights${arg}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserDocumentsList: builder.query({
      query: (arg) => `/user-documents/table${arg}`,
      providesTags: (result, error) => useProvideTags(result, error, ['USER_DOCUMENTS']),
    }),
    downloadUserDocument: builder.mutation({
      query: (id) => ({
        url: `/user-documents/download/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    confirmDocument: builder.mutation({
      query: (id) => ({
        url: `/user-documents/confirm/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['USER_DOCUMENTS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getServiceTypeList: builder.query({
      query: () => 'flight/invoice-type-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftTypeList: builder.query({
      query: (arg) => `/aircraft-types${arg}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftProfileList: builder.query({
      query: (arg) => `/aircraft${arg}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftProfile: builder.mutation({
      query: (arg) => `/aircraft/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeAircraftProfile: builder.mutation({
      query: (data) => ({
        url: '/aircraft',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    crewUploadImg: builder.mutation({
      query: (data) => ({
        url: '/crew-photo',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    downloadCrewDoc: builder.mutation({
      query: ({ crew_id, document_id }) => ({
        url: `crew-doc/download?crew_id=${crew_id}&document_id=${document_id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    crewUploadSing: builder.mutation({
      query: (data) => ({
        url: '/crew-sign',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateAircraftProfile: builder.mutation({
      query: (data) => ({
        url: `/aircraft/${data.id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteAircraftProfile: builder.mutation({
      query: (arg) => ({
        url: `/aircraft/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelPaymentsList: builder.query({
      query: (arg) => `/fuel/payments/table${arg}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlight: builder.mutation({
      query: (arg) => `/flights/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getSessionByAirport: builder.mutation({
      query: ({ service, airports }) => `/flight/suppliers?service=${service}&airport=${airports}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getServicesByAirport: builder.query({
      query: (airports) => `/flight/services?airport=${airports}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPaymentByAirport: builder.query({
      query: () => 'flight/payment-methods',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftType: builder.mutation({
      query: (arg) => `/aircraft-types/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAllAircraftType: builder.query({
      query: () => '/aircraft-types',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsPositionTypes: builder.query({
      query: () => '/ods-position-types',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewNames: builder.query({
      query: (data) => `/crew-names/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserDocuments: builder.query({
      query: () => '/user-documents/user-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserGroups: builder.query({
      query: () => '/user-groups-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPositions: builder.query({
      query: () => '/positions-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    crewFlightTime: builder.mutation({
      query: (data) => ({
        url: '/crew-flight-time',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeAircraftType: builder.mutation({
      query: (data) => ({
        url: '/aircraft-types',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    copyFlight: builder.mutation({
      query: (data) => ({
        url: `/copy-flight/${data}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    copyInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoice-copy/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateAircraftType: builder.mutation({
      query: (data) => ({
        url: `/aircraft-types/${data.id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteAircraftType: builder.mutation({
      query: (arg) => ({
        url: `/aircraft-types/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAirTypeList: builder.query({
      query: () => '/aircraft/types/list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsStatuses: builder.query({
      query: () => '/ods-statuses',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getServiceSuppliersList: builder.mutation({
      query: ({ country_id, service_id }) => `/service-suppliers-list/byCountry?country_id=${country_id}&service_id=${service_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getServiceSupplier: builder.query({
      query: (arg) => `/service-suppliers/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsAta: builder.query({
      query: () => '/ods-ata',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrew: builder.query({
      query: (data) => `/crew${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewDoc: builder.query({
      query: (data) => `/crewdoc${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOperatorList: builder.query({
      query: () => '/operators/list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelPayment: builder.mutation({
      query: (id) => `/fuel/payments/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewById: builder.query({
      query: (id) => `/crew/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeFuelPayment: builder.mutation({
      query: (data) => ({
        url: '/fuel/payments',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateCrew: builder.mutation({
      query: ({ data, id }) => ({
        url: `/crew/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    odsActions: builder.mutation({
      query: ({ data, id }) => ({
        url: `/ods-actions/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createOdsAction: builder.mutation({
      query: (data) => ({
        url: '/ods-actions',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createOdsDefect: builder.mutation({
      query: (data) => ({
        url: '/create-ods-defect',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['ODS_DEFECT'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createOds: builder.mutation({
      query: (data) => ({
        url: '/create-ods',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateCrewDoc: builder.mutation({
      query: ({ data, id }) => ({
        url: `/crewdoc/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFuelPayment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/fuel/payments/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFuelPayment: builder.mutation({
      query: (id) => ({
        url: `/fuel/payments/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftList: builder.query({
      query: (data = '') => `/aircraft-list${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsActions: builder.query({
      query: (data = '') => `/ods-actions${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    odsActionsExportToExcel: builder.mutation({
      query: (data) => ({
        url: `/ods-actions/export${data}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsActionFiles: builder.mutation({
      query: (data) => ({
        url: `/ods-actions/get-files/${data}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCompaniesList: builder.query({
      query: () => '/companies-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelSuppliersList: builder.query({
      query: () => '/fuel-supplier-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightFuelAirport: builder.query({
      query: (data) => `/flight/fuel?airport=${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelMethodsList: builder.query({
      query: () => '/fuel/methods',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // getFuelPrice: builder.query({
    //   query: (id) => `/fuel/price/${id}`,
    // }),
    getPersonsList: builder.query({
      query: (id) => `/person-list/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCargoList: builder.query({
      query: () => '/cargo-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsDefects: builder.query({
      query: ({ ods_id, aircraft_id }) => `/ods-defects?ods_id=${ods_id}&aircraft_id=${aircraft_id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['ODS_DEFECT']),
    }),
    getAirportList: builder.query({
      query: (data) => `/airport-list?${encodeObjectToURIParams(data)}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserList: builder.query({
      query: () => '/users-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getServiceList: builder.query({
      query: () => '/service-type-list',
    }),
    getCrewList: builder.query({
      query: (data = '') => `/crew-list${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewListByPost: builder.query({
      query: (post) => `/crew-list?post=${post}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewListWithParams: builder.query({
      query: ({ crew_line_check }) => `/crew-list?crew_line_check=${crew_line_check}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewListWithParameters: builder.query({
      query: (params) => `/crew-list${params}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsToc: builder.query({
      query: () => '/ods-toc',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftTypesList: builder.query({
      query: () => '/aircraft/types/list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCountryList: builder.query({
      query: () => '/country-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getQuotationsList: builder.query({
      query: (data) => `/fleet-table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFewOdsForms: builder.query({
      query: (data) => `/ods-form${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getExportExcel: builder.mutation({
      query: ({ id, year, months }) => ({
        url: `/crew-ftl/${id}/download?year=${year}&months=${months}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsExportExcel: builder.mutation({
      query: ({ aircraft_id }) => ({
        url: `/ods-download?aircraft_id=${aircraft_id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelPriceExportExcel: builder.mutation({
      query: () => ({
        url: '/fuel/price-download',
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightsSchedule: builder.query({
      query: (data) => `/flights-schedule${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightToggleStatus: builder.mutation({
      query: (data) => `/flight/toggle-status/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightToggleCancel: builder.mutation({
      query: (data) => `/flight/toggle-cancel/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightToggleInvoiceStatus: builder.mutation({
      query: (data) => `/flight/toggle-invoice-status/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightTogglePermissionStatus: builder.mutation({
      query: (data) => `/flight/toggle-permission-status/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getMainDeckList: builder.query({
      query: () => '/main/deck',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFtlLogbook: builder.query({
      query: ({ id, data }) => `/crew-ftl/logbook/${id}${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendLogBook: builder.mutation({
      query: ({ id, data }) => ({
        url: `crew-ftl/logbook/send/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    changeFees: builder.mutation({
      query: ({ id, data }) => ({
        url: `airport-fees/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFtl: builder.query({
      query: ({ id, data }) => `/crew-ftl/${id}${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getLogBook: builder.query({
      query: ({ id, data }) => `/crew-ftl/logbook/${id}${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    receiveFtl: builder.mutation({
      query: ({ id, data }) => `/crew-ftl/${id}${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsRecalculate: builder.mutation({
      query: (data) => `ods-recalculate/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: ['Ods-table'],
    }),
    getOds: builder.query({
      query: (data) => `/ods${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['Ods-table']),
    }),
    getOdsOther: builder.query({
      query: (data) => `/ods-other-table${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['Ods-table']),
    }),
    getOdsById: builder.query({
      query: (id) => `/ods/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelBalance: builder.query({
      query: () => '/fuel/balance',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsFormsById: builder.mutation({
      query: (id) => `/ods/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateOds: builder.mutation({
      query: ({ data, id }) => ({
        url: `/ods/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createMaintenance: builder.mutation({
      query: (data) => ({
        url: '/create-maintenance-ods',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewRoster: builder.query({
      query: ({ data, type }) => `/crew-roster${data}&type=${type}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getDutyList: builder.query({
      query: (type) => `/crew-roster/duty-list?type=${type}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // Training Module Store
    getTrainingEventsCalendar: builder.query({
      query: ({ date_start, date_end }) => `/training_module/schedule_calendar?date_start=${date_start}&date_end=${date_end}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTrainingSchedule: builder.query({
      query: (data) => `/training_module/schedule${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['TRAINING_SCHEDULE_UPDATED']),
    }),
    getTrainingAheadTable: builder.mutation({
      query: () => '/training_module/training_ahead',
      providesTags: (result, error) => useProvideTags(result, error, ['TRAINING_SCHEDULE_UPDATED']),
    }),
    getProgramsReviewedTable: builder.mutation({
      query: () => '/training_module/programs_reviewed',
      providesTags: (result, error) => useProvideTags(result, error, ['TRAINING_SCHEDULE_UPDATED']),
    }),
    createTrainingSchedule: builder.mutation({
      query: (data) => ({
        url: '/training_module/schedule',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['TRAINING_SCHEDULE_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTrainingSchedule: builder.mutation({
      query: (data) => ({
        url: `/training_module/schedule/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateTrainingSchedule: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/schedule/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getTrainingFlightCrewTable: builder.query({
      query: (data) => `/crew_table/pilots_table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
      transformResponse: ({ data, ...response }) => {
        const pilotsWithData = data
          ? data?.map((e, i) => ({ ...e, index: i + 1 }))
          : data;

        return { ...response, data: pilotsWithData };
      },
    }),
    getTrainingFlightCrewDefaultDocumentsTable: builder.query({
      query: (data) => `training_module/documents${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTrainingFlightCrewDefaultBlock: builder.mutation({
      query: (data) => ({
        url: '/training_module/documents',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getTrainingFlightCrewDefaultBlock: builder.mutation({
      query: (id) => `/training_module/documents/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateTrainingFlightCrewDefaultBlock: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/documents/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTrainingFlightCrewDefaultDocumentsTable: builder.mutation({
      query: (id) => ({
        url: `/training_module/documents/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    // training pilot page
    getTrainingPilotData: builder.query({
      query: (id) => `/training_module/pilot/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['FLIGHT_CREW_DOC_UPDATED']),
    }),
    updateTrainingPilotTags: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/pilot_tags/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getTrainingPilotDocsTable: builder.query({
      query: (data) => `/training_module/pilot_training_documents${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTrainingPilotDocs: builder.mutation({
      query: (data) => ({
        url: '/training_module/pilot_training_documents_create',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['FLIGHT_CREW_DOC_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateTrainingPilotDocsTable: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/pilot_training_documents/update/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['FLIGHT_CREW_DOC_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTrainingPilotDocsTable: builder.mutation({
      query: (data) => ({
        url: `/training_module/pilot_training_documents/delete_row/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['FLIGHT_CREW_DOC_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // pilot page files
    getTRGFile: builder.mutation({
      query: (id) => ({
        url: `/training_module/pilot_training_documents/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTRGFile: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/pilot_training_documents/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['TRAINING_PILOT_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTRGFile: builder.mutation({
      query: (id) => ({
        url: `/training_module/pilot_training_documents/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['TRAINING_PILOT_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getFDBKFile: builder.mutation({
      query: (id) => ({
        url: `/training_module/pilot_training_documents/feedback/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createFDBKFile: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/pilot_training_documents/feedback/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['TRAINING_PILOT_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteFDBKFile: builder.mutation({
      query: (id) => ({
        url: `/training_module/pilot_training_documents/feedback/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['TRAINING_PILOT_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    // training personnel page
    createInstructor: builder.mutation({
      query: (data) => ({
        url: '/training_module/training_personnel',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INSTRUCTORS_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInstructorTable: builder.query({
      query: (data) => `/training_module/training_personnel${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['INSTRUCTORS_UPDATED']),
    }),
    updateInstructorTable: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/training_personnel/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INSTRUCTORS_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteInstructorRow: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_personnel/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INSTRUCTORS_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // instructor page
    getInstructorData: builder.query({
      query: (id) => `/training_module/training_personnel/${id}`,
      providesTags: (result, error) => useProvideTags(result, error, ['INSTRUCTOR_UPDATED']),
    }),
    updateInstructorTags: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/instructor/tags/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getInstructorDocumentTable: builder.query({
      query: (data) => `/training_module/instructor/documents${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createInstructorDocumentTable: builder.mutation({
      query: (data) => ({
        url: '/training_module/instructor/documents',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateInstructorDocumentTable: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/instructor/documents/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteInstructorDocumentTable: builder.mutation({
      query: (data) => ({
        url: `/training_module/instructor/documents/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // instructor files
    getInstructorDoc: builder.mutation({
      query: (id) => ({
        url: `/training_module/instructor_document/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createInstructorDoc: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/instructor_document/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['INSTRUCTOR_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteInstructorDoc: builder.mutation({
      query: (id) => ({
        url: `/training_module/instructor_document/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['INSTRUCTOR_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    // training programs
    getTrainingProgramBlockList: builder.query({
      query: () => '/training_module/training_program_block_list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTrainingProgramTable: builder.query({
      query: (data) => `/training_module/training_program${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTrainingProgramTable: builder.mutation({
      query: (data) => ({
        url: '/training_module/training_program',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateTrainingProgramTable: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/training_program/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteTrainingProgramTable: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_program/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // training program SLB file
    getTrainingProgramSLB: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_program_slb/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTrainingProgramSLB: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/training_program_slb/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['INSTRUCTOR_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTrainingProgramSLB: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_program_slb/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['INSTRUCTOR_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    // training program FDBK file
    getTrainingProgramFDBK: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_program_fdbk/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTrainingProgramFDBK: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/training_program_fdbk/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['INSTRUCTOR_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTrainingProgramFDBK: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_program_fdbk/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['INSTRUCTOR_DOCS_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    // training program blocks
    getTrainingProgramBlocksTable: builder.query({
      query: (data) => `/training_module/training_program_block${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createTrainingProgramBlocksTable: builder.mutation({
      query: (data) => ({
        url: '/training_module/training_program_block',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    updateTrainingProgramBlocksTable: builder.mutation({
      query: ({ data, id }) => ({
        url: `/training_module/training_program_block/${id}`,
        method: 'PATCH',
        body: data,
        cache: 'no-cache',
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteTrainingProgramBlocksTable: builder.mutation({
      query: (id) => ({
        url: `/training_module/training_program_block/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getTrainingProgramFiles: builder.mutation({
      query: ({ id, type }) => ({
        url: `/training_module/training_program/files/${id}?type=${type}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCrewDocumentFiles: builder.mutation({
      query: ({ id, type }) => ({
        url: `/training_module/pilot_training_documents/files/${id}?type=${type}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadTrainingProgramFiles: builder.mutation({
      query: ({ id, data }) => ({
        url: `/training_module/training_program/files/upload/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadCrewDocumentsFiles: builder.mutation({
      query: ({ id, data }) => ({
        url: `/training_module/pilot_training_documents/files/upload/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openTrainingFiles: builder.mutation({
      query: ({ id }) => ({
        url: `/training_module/training/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteTrainingFiles: builder.mutation({
      query: ({ id }) => ({
        url: `/training_module/training/files/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // end of training module
    getCrewSignature: builder.query({
      query: ({ index, data }) => `/crew-signature/${index}${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateDutyList: builder.mutation({
      query: (data) => ({
        url: '/crew-roster/store',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      transformErrorResponse: (responce) => responce,
    }),
    deleteCrewRosterDuty: builder.mutation({
      query: (id) => ({
        url: `/crew-roster/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFlights: builder.mutation({
      query: (id) => ({
        url: `/flights/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getQuotation: builder.mutation({
      query: (id) => `/fleet/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateQuotation: builder.mutation({
      query: ({ data, id }) => ({
        url: `/fleet/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createQuotation: builder.mutation({
      query: (data) => ({
        url: '/fleet',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteQuotation: builder.mutation({
      query: (id) => ({
        url: `/fleet/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendQuotation: builder.mutation({
      query: (data) => ({
        url: '/send-fleet',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendService: builder.mutation({
      query: (data) => ({
        url: '/flight/service-send',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendFuelOrder: builder.mutation({
      query: (data) => ({
        url: '/flight/fuel-order-send',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendCharterConfirmation: builder.mutation({
      query: (data) => ({
        url: '/flight/charter-info',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteCrewDoc: builder.mutation({
      query: (id) => ({
        url: `/crewdoc/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteCrew: builder.mutation({
      query: (id) => ({
        url: `/crew/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteOdsActions: builder.mutation({
      query: (id) => ({
        url: `/ods-actions/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createFlightFromFleet: builder.mutation({
      query: (id) => ({
        url: `/flight-from-fleet/${id}`,
        method: 'POST',
        body: id,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createCrewDoc: builder.mutation({
      query: (body) => ({
        url: '/crewdoc',
        method: 'POST',
        body,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendUserDocuments: builder.mutation({
      query: (body) => ({
        url: '/user-documents/send',
        method: 'POST',
        body,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadCrewDoc: builder.mutation({
      query: (body) => ({
        url: '/crew-doc',
        method: 'POST',
        body,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendRoster: builder.mutation({
      query: (body) => ({
        url: '/crew-roster/send-roster',
        method: 'POST',
        body,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    copyFleet: builder.mutation({
      query: (id) => ({
        url: `/copy-fleet/${id}`,
        method: 'POST',
        body: id,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    addCrew: builder.mutation({
      query: (data) => ({
        url: '/crew',
        method: 'POST',
        body: data,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOperatorsTableList: builder.query({
      query: (data) => `/operator${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOperator: builder.mutation({
      query: (arg) => `/operator/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeOperator: builder.mutation({
      query: (data) => ({
        url: '/operator',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateOperator: builder.mutation({
      query: (data) => ({
        url: `/operator/${data.id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteOperator: builder.mutation({
      query: (arg) => ({
        url: `/operator/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAirportTableList: builder.query({
      query: (data) => `/airport${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAirport: builder.mutation({
      query: (arg) => `/airport/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeAirport: builder.mutation({
      query: (data) => ({
        url: '/airport',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    fuelBalance: builder.mutation({
      query: (data) => ({
        url: '/fuel/balance',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),

    updateAirport: builder.mutation({
      query: (data) => ({
        url: `/airport/${data.id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteAirport: builder.mutation({
      query: (arg) => ({
        url: `/airport/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCaaTableList: builder.query({
      query: (data) => `/caa${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCaa: builder.mutation({
      query: (arg) => `/caa/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeCaa: builder.mutation({
      query: (data) => ({
        url: '/caa',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateCaa: builder.mutation({
      query: (data) => ({
        url: `/caa/${data.id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteCaa: builder.mutation({
      query: (arg) => ({
        url: `/caa/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelPriceTableList: builder.query({
      query: (data) => `/fuel/price${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelPrice: builder.mutation({
      query: (arg) => `/fuel/price/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeFuelPrice: builder.mutation({
      query: (data) => ({
        url: '/fuel/price',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFuelPrice: builder.mutation({
      query: ({ data, id }) => ({
        url: `/fuel/price/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFuelPriceField: builder.mutation({
      query: ({ data, id }) => ({
        url: `/fuel/update-price/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFuelPrice: builder.mutation({
      query: (arg) => ({
        url: `/fuel/price/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelUplift: builder.mutation({
      query: (arg) => `/fuel/uplifts/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelUpliftTableList: builder.query({
      query: (data) => `/fuel/uplifts${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFuelUplift: builder.mutation({
      query: ({ data, id }) => ({
        url: `/fuel/uplifts/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteFuelUplift: builder.mutation({
      query: (arg) => ({
        url: `/fuel/uplifts/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFuelUpliftExportExcel: builder.mutation({
      query: () => ({
        url: '/fuel/uplifts/export',
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCompanyTableList: builder.query({
      query: (data) => `/company${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCompany: builder.mutation({
      query: (arg) => `/company/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeCompany: builder.mutation({
      query: (data) => ({
        url: '/company',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateCompany: builder.mutation({
      query: (data) => ({
        url: `/company/${data.id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteCompany: builder.mutation({
      query: (arg) => ({
        url: `/company/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getSupplierTableList: builder.query({
      query: (data) => `/service-suppliers${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getSupplier: builder.mutation({
      query: (arg) => `/service-suppliers/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeSupplier: builder.mutation({
      query: (data) => ({
        url: '/service-suppliers',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    updateSupplier: builder.mutation({
      query: ({ data, id }) => ({
        url: `/service-suppliers/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteSupplier: builder.mutation({
      query: (arg) => ({
        url: `/service-suppliers/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getFdp: builder.mutation({
      query: (data) => ({
        url: '/getFdp',
        method: 'POST',
        body: data,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeFlight: builder.mutation({
      query: (data) => ({
        url: '/flights',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFlight: builder.mutation({
      query: (args) => ({
        url: `/flights/${args.id}`,
        method: 'PATCH',
        body: args.data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    exportFlightsToExcel: builder.mutation({
      query: (data) => ({
        url: `/flights/export${data}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
    createOdsFromFlight: builder.mutation({
      query: (args) => ({
        url: `/create-ods-from-flight/${args}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storeOdsFromHop: builder.mutation({
      query: (args) => ({
        url: `/create-ods-from-hop/${args}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPlaningFuelAirportSuppliers: builder.query({
      query: (arg) => `/flight/fuel-suppliers/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateFuelSuppliers: builder.mutation({
      query: (data) => ({
        url: '/flight/fuel-suppliers',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendPermitMail: builder.mutation({
      query: (data) => ({
        url: '/flight/send-permit',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendContractAndInvoice: builder.mutation({
      query: (arg) => ({
        url: `/flight/contract-and-invoice/${arg.id}`,
        method: 'POST',
        body: arg.data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightContract: builder.mutation({
      query: (arg) => ({
        url: `/flight/preview-contract/${arg.id}`,
        method: 'POST',
        body: arg.data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAdditionalInvoicePdf: builder.mutation({
      query: (arg) => ({
        url: `/flight/preview-additional-invoice/${arg.id}`,
        method: 'POST',
        body: arg.data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getIrsPdf: builder.mutation({
      query: (arg) => ({
        url: `/download-work-order/record-sheet/${arg.id}`,
        method: 'GET',
        body: arg.data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getViewPdf: builder.mutation({
      query: (arg) => ({
        url: `/download-work-order/view/${arg.id}`,
        method: 'GET',
        body: arg.data,
        cache: 'no-cache',
      }),
      invalidatesTags: (result) => (result ? ['WORK_ORDER_FILES'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    WOTaskExportToExcel: builder.mutation({
      query: (data) => ({
        url: `/work-order-task/export${data}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getWorkOrderFiles: builder.mutation({
      query: (arg) => ({
        url: `/download-work-order/files/${arg.id}`,
        method: 'GET',
        body: arg.data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlightInvoice: builder.mutation({
      query: (arg) => ({
        url: `/flight/preview-invoice/${arg.id}`,
        method: 'POST',
        body: arg.data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createUser: builder.mutation({
      query: (arg) => ({
        url: '/users',
        method: 'POST',
        body: arg,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    changePassword: builder.mutation({
      query: (arg) => ({
        url: `/users/change-password/${arg.id}`,
        method: 'POST',
        body: arg.data,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateUser: builder.mutation({
      query: (args) => ({
        url: `/users/${args.id}`,
        method: 'PATCH',
        body: args.data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUsersTable: builder.query({
      query: (data) => `/users-table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUser: builder.mutation({
      query: (arg) => `/users/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteUser: builder.mutation({
      query: (arg) => ({
        url: `/users/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUsersPositionsTable: builder.query({
      query: (data) => `/position${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserPositions: builder.query({
      query: (arg) => `/position/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteUserPositions: builder.mutation({
      query: (arg) => ({
        url: `/position/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createUserPositions: builder.mutation({
      query: (data) => ({
        url: '/position',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateUserPositions: builder.mutation({
      query: (args) => ({
        url: `/position/${args.id}`,
        method: 'PATCH',
        body: args.data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUsersGroupsTable: builder.query({
      query: (data) => `/user-group${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteUserGroup: builder.mutation({
      query: (arg) => ({
        url: `/user-group/${arg}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserGroup: builder.query({
      query: (arg) => `/user-group/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createUserGroup: builder.mutation({
      query: (data) => ({
        url: '/user-group',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateUserGroup: builder.mutation({
      query: (args) => ({
        url: `/user-group/${args.id}`,
        method: 'PATCH',
        body: args.data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAtrMaintenance: builder.query({
      query: (data) => `/atr-maintenance${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['atr-mnt-table']),
    }),
    getAtrMaintenanceWoHistory: builder.query({
      query: (data) => `/atr-maintenance-wo-history?maintenance_id=${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getDetailMaintenance: builder.mutation({
      query: (data) => `/atr-maintenance/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getRefFileListMaintenance: builder.mutation({
      query: ({ id }) => ({
        url: `/atr-maintenance/files/list/${id}`,
        method: 'GET',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openRefFileMaintenance: builder.mutation({
      query: ({ id }) => ({
        url: `/atr-maintenance/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadRefFileMaintenance: builder.mutation({
      query: ({ id, data }) => ({
        url: `/atr-maintenance/files/upload/${id}`,
        method: 'POST',
        body: data,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteRefFileMaintenance: builder.mutation({
      query: ({ id }) => ({
        url: `/atr-maintenance/files/delete/${id}`,
        method: 'DELETE',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createArtMaintenance: builder.mutation({
      query: (data) => ({
        url: '/atr-maintenance',
        method: 'POST',
        body: data,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteArtMaintenance: builder.mutation({
      query: (data) => ({
        url: `/atr-maintenance/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateArtMaintenance: builder.mutation({
      query: ({ data, id }) => ({
        url: `/atr-maintenance/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    maintenanceDownload: builder.mutation({
      query: (args) => ({
        url: `/atr-maintenance-download?aircraft_id=${args.id}&version=${args.version}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getWorkOrder: builder.query({
      query: (data) => `/work-order${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteWorkOrder: builder.mutation({
      query: (data) => ({
        url: `/work-order/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    confirmDeleteWorkOrder: builder.mutation({
      query: (data) => ({
        url: `/work-order-confirm-destroy/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createWorkOrder: builder.mutation({
      query: (data) => ({
        url: '/work-order',
        method: 'POST',
        body: data,
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getWorkOrderDetail: builder.mutation({
      query: (data) => `/work-order/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getWorkOrderTask: builder.query({
      query: (data) => `/work-order-task${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['WO_UPDATED']),
    }),
    updateWorkOrder: builder.mutation({
      query: ({ data, id }) => ({
        url: `/work-order/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        invalidatesTags: (result) => (result ? ['WO_UPDATED'] : []),
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteWorkOrderTask: builder.mutation({
      query: (data) => ({
        url: `/work-order-task/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateWorkOrderTask: builder.mutation({
      query: ({ data, id }) => ({
        url: `/work-order-task/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    workOrderTaskDetail: builder.mutation({
      query: (data) => `/work-order-task/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getWorkOrderTaskFile: builder.query({
      query: (data) => `/work-order-task-files${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['WO_FILES']),
    }),
    deleteWorkOrderTaskFile: builder.mutation({
      query: (data) => ({
        url: `/work-order-task-files/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    downloadWorkOrderTaskFile: builder.mutation({
      query: (id) => ({
        url: `/work-order-task-files/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    downloadCrewSignaturesFile: builder.mutation({
      query: (id) => ({
        url: `/crew-signature-download/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadWorkOrderTaskFile: builder.mutation({
      query: (body) => ({
        url: '/work-order-task-files',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => (result ? ['WO_FILES'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createWorkOrderPartsPair: builder.mutation({
      query: (data) => ({
        url: '/work-order-task-parts-pair',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['WO_PARTS_PAIR'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    validateWorkOrderPartsPair: builder.mutation({
      query: (data) => ({
        url: '/work-order-task-parts-pair/validate',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['WO_PARTS_PAIR'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateWorkOrderPartsPair: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update/work-order-task-parts-pair/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['WO_PARTS_PAIR'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getWorkOrderTaskPartsPair: builder.query({
      query: (data) => `/work-order-task-parts-pair${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['WO_PARTS_PAIR']),
    }),
    deleteWorkOrderTaskPartsPair: builder.mutation({
      query: (data) => ({
        url: `/work-order-task-parts-pair/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateWorkOrderTaskPartsPair: builder.mutation({
      query: ({ data, id }) => ({
        url: `/work-order-task-parts-pair/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    createConsumable: builder.mutation({
      query: (data) => ({
        url: '/work-order-task-consumable',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['CONSUMABLE'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getConsumableList: builder.query({
      query: (data) => `/work-order-task-consumable${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['CONSUMABLE']),
    }),
    deleteConsumable: builder.mutation({
      query: (data) => ({
        url: `/work-order-task-consumable/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateConsumable: builder.mutation({
      query: ({ data, id }) => ({
        url: `/work-order-task-consumable/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    createTaskTools: builder.mutation({
      query: (data) => ({
        url: '/work-order-task-tools',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['TASK_TOOLS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTaskToolsList: builder.query({
      query: (data) => `/work-order-task-tools${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['TASK_TOOLS']),
    }),
    deleteTaskTools: builder.mutation({
      query: (data) => ({
        url: `/work-order-task-tools/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateTaskTools: builder.mutation({
      query: ({ data, id }) => ({
        url: `/work-order-task-tools/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    getTaskList: builder.query({
      query: (id) => `/task-list/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getTaskListSearch: builder.query({
      query: ({ aircraft_id, search }) => `/task-list/${aircraft_id}${search ? `?search=${search}` : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    taskListSearch: builder.query({
      query: ({ aircraft_id, search }) => `/task-list/${aircraft_id}/list?search=${search}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createWOTask: builder.mutation({
      query: (data) => ({
        url: '/work-order-task',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    assignWOTask: builder.mutation({
      query: (data) => ({
        url: '/assign-wo-task',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['WO_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchWOTask: builder.query({
      query: ({ aircraft_id, search }) => `task_list/search/${aircraft_id}?search=${search}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPrintODSData: builder.query({
      query: (id) => `/ods-print-data/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAirList: builder.mutation({
      query: (data = '') => `/aircraft-list?type=${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticByCompany: builder.mutation({
      query: (data) => `/statistic/company${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticDetailsByCompany: builder.mutation({
      query: (data) => `/statistic/company/details${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticByDeparture: builder.mutation({
      query: (data) => `/statistic/departure${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticByDestination: builder.mutation({
      query: (data) => `/statistic/destination${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticBySector: builder.mutation({
      query: (data) => `/statistic/sector${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStatisticByProfit: builder.mutation({
      query: (data) => `/statistic/profit${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getBankList: builder.query({
      query: (data) => `/bank${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createBank: builder.mutation({
      query: (data) => ({
        url: '/bank',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getBankInfo: builder.mutation({
      query: (id) => `/bank/${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateBank: builder.mutation({
      query: ({ data, id }) => ({
        url: `/bank/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    updateBankTransactionsCurrency: builder.mutation({
      query: (id) => ({
        url: `/update-bank-transactions-currency/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        providesTags: (result, error) => useProvideTags(result, error),
      }),
    }),
    deleteBank: builder.mutation({
      query: (id) => ({
        url: `/bank/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getBankInvoicesList: builder.query({
      query: (show_in_transactions) => `/banks-list?transactions=${show_in_transactions}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getBankAccountNumbersList: builder.mutation({
      query: (id) => `/account-numbers-list?bank_id=${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getBankAccountList: builder.query({
      query: (id) => `/account-numbers-list?bank_id=${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCardsList: builder.mutation({
      query: (id) => `/cards-list?account_number_id=${id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInvoiceTable: builder.query({
      query: (data) => `/invoice${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInvoice: builder.mutation({
      query: (data) => `/invoice/${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCurrencyList: builder.query({
      query: () => '/currency',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getDepartmentsList: builder.query({
      query: () => '/departments-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getFlights: builder.query({
      query: () => '/flights-list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteInvoice: builder.mutation({
      query: (data) => ({
        url: `/invoice/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteConfirmation: builder.mutation({
      query: (data) => ({
        url: `/invoice-delete/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateInvoice: builder.mutation({
      query: ({ data, id }) => ({
        url: `/invoice/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getDepartmentTable: builder.query({
      query: (data) => `/department${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['DEPARTMENT_UPDATED']),
    }),
    createDepartment: builder.mutation({
      query: (data) => ({
        url: '/department',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['DEPARTMENT_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteDepartment: builder.mutation({
      query: (data) => ({
        url: `/department/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateDepartment: builder.mutation({
      query: ({ data, id }) => ({
        url: `/department/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createCurrency: builder.mutation({
      query: (data) => ({
        url: '/currency',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['CURRENCY_UPDATED'] : []),
    }),
    createInvoice: builder.mutation({
      query: (data) => ({
        url: '/invoice',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCurrency: builder.query({
      query: () => '/currency',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getCurrencyTable: builder.query({
      query: (data) => `/currency-table${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['CURRENCY_UPDATED']),
    }),
    deleteCurrency: builder.mutation({
      query: (data) => ({
        url: `/currency/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateCurrency: builder.mutation({
      query: ({ data, id }) => ({
        url: `/currency/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInvoiceExcel: builder.mutation({
      query: ({ year, months }) => ({
        url: `/invoice-download?year=${year}&months=${months}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createPurpose: builder.mutation({
      query: (data) => ({
        url: '/payment-purpose',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['PURPOSES_UPDATED'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPurposes: builder.query({
      query: () => '/payment-purpose/list',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getPurposesTable: builder.query({
      query: (data) => `/payment-purpose${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['PURPOSES_UPDATED']),
    }),
    deletePurpose: builder.mutation({
      query: (data) => ({
        url: `/payment-purpose/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updatePurpose: builder.mutation({
      query: ({ data, id }) => ({
        url: `/payment-purpose/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    createInvoiceDoc: builder.mutation({
      query: (data) => ({
        url: '/finance/invoice_management',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INVOICE_REQUEST_SEND'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUserInvoiceRequests: builder.query({
      query: (data) => `/invoice-request/userList${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['INVOICE_REQUEST_SEND']),
    }),
    getApproveInvoiceRequests: builder.query({
      query: (data) => `/invoice-request/table${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    downloadUserInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoice-request/download/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteUserInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoice-request/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    approveInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoice-request/approve/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    declineInvoice: builder.mutation({
      query: ({ id, data }) => ({
        url: `/invoice-request/decline/${id}`,
        method: 'POST',
        body: { decline_purpose: data.decline_purpose },
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    downloadInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoice-request/download/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadProofOfPayment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/invoice-request/upload_proof/${id}`,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getProofOfPayment: builder.mutation({
      query: (id) => ({
        url: `/invoice-request/download_proof/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateInvoiceByAccount: builder.mutation({
      query: ({ data, id }) => ({
        url: `/invoice-request/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getLoadedAirports: builder.query({
      query: ({ year, months }) => `/maps/loaded_airports?year=${year}&months=${months}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAtrVersionByStatus: builder.query({
      query: (args) => `/atr-maintenance-version?version=${args.version}&aircraft_id=${args.aircraft_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    changeAtrVersion: builder.mutation({
      query: (data) => ({
        url: '/atr-maintenance-version',
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: ['atr-mnt-table'],
    }),
    returnToStoragePart: builder.mutation({
      query: ({ id }) => ({
        url: `aircraft_body/return_to_storage/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getAircraftBodyTable: builder.query({
      query: (data) => `/aircraft_body${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getUtilizationReport: builder.mutation({
      query: (data) => ({
        url: `/utilization_report${data}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    exportToExcelUtilizationReport: builder.mutation({
      query: (data) => ({
        url: `/utilization_report/export${data}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getMntAircraftTable: builder.query({
      query: (data) => `/mnt_aircraft${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getMntAircraft: builder.mutation({
      query: (arg) => `/mnt_aircraft/${arg.id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateMntAircraft: builder.mutation({
      query: (args) => ({
        url: `/mnt_aircraft/${args.id}`,
        method: 'PATCH',
        body: args.data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    readOdsMntAircraft: builder.mutation({
      query: (arg) => `/mnt_aircraft_read_ods/${arg}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // Daily Availability
    getDailyAricraftData: builder.query({
      query: () => '/daily_availability/aircraft',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateDailyAricraftData: builder.mutation({
      query: (data) => ({
        url: '/daily_availability/aircraft',
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendDailyEmail: builder.mutation({
      query: (data) => ({
        url: '/daily_availability/aircraft',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // notifications
    getNotificationList: builder.query({
      query: () => '/notifications?pageSize=30&page=1',
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    readNotification: builder.mutation({
      query: (data) => ({
        url: `/notifications/${data}`,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    readAllNotification: builder.mutation({
      query: () => ({
        url: '/notifications/read_all',
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openNotificationFile: builder.mutation({
      query: ({ path }) => ({
        url: `/ods-print-all/file?path=${path}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // emails
    getDailyEmailList: builder.query({
      query: (data) => `/daily_availability/emails${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['EMAIL_UPDATED']),
    }),
    createDailyEmail: builder.mutation({
      query: (data) => ({
        url: '/daily_availability/emails',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['CURRENCY_UPDATED'] : []),
    }),
    deleteDailyEmail: builder.mutation({
      query: (data) => ({
        url: `/daily_availability/emails/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateDailyEmail: builder.mutation({
      query: ({ data, id }) => ({
        url: `/daily_availability/emails/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    printAllOds: builder.mutation({
      query: (data) => ({
        url: '/ods-print-all',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOdsAutocomplete: builder.query({
      query: ({ search, aircraft_id }) => `/ods_autocomplete?search=${search}&aircraft_id=${aircraft_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getMyOrdersTable: builder.query({
      query: (data) => `/logistics/my_orders${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['ORDERS']),
    }),
    storeOrder: builder.mutation({
      query: (data) => ({
        url: '/logistics/my_orders',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['ORDERS'] : []),
    }),
    getStorageOrdersTable: builder.query({
      query: (data) => `/logistics/storage/parts${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['ORDERS']),
    }),
    updateStorageOrder: builder.mutation({
      query: ({ data, id }) => ({
        url: `/logistics/storage/parts/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['ORDERS'] : []),
    }),
    deleteStorageOrder: builder.mutation({
      query: (data) => ({
        url: `/logistics/storage/parts/${data}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['ORDERS'] : []),
    }),
    downloadPartCertificate: builder.mutation({
      query: (id) => ({
        url: `/logistics/storage/parts/${id}/cert`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadPartCertificate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/storage/parts/${id}/cert`,
        method: 'POST',
        body: data,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deletePartCertificate: builder.mutation({
      query: (id) => ({
        url: `/logistics/storage/parts/${id}/cert`,
        method: 'DELETE',
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOrders: builder.query({
      query: (params) => `/logistics/orders${params}`,
      providesTags: (result, error) => useProvideTags(result, error, ['ALL_ORDERS']),
    }),
    getOrdersTableInternal: builder.query({
      query: (variant) => {
        const isBoard = !variant.includes('sortDir');

        return `/logistics/internal/orders${variant}`;

        // return isBoard ? '/logistics/internal/orders?type_display=board' : `/logistics/internal/orders${variant}`;
      },
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getOrdersTable: builder.query({
      query: (data) => `/logistics/orders${data}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    storePart: builder.mutation({
      query: (data) => ({
        url: '/logistics/storage/parts',
        method: 'POST',
        body: data,
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getStoragePartsAutocomplete: builder.query({
      query: ({ search, is_aircraft_body, search_row }) => `logistics/storage/autocomplete/parts?search=${search}${is_aircraft_body ? '&is_aircraft_body=true' : ''}${search_row ? `&search_row=${search_row}` : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadOdsActionFiles: builder.mutation({
      query: ({ data, id }) => ({
        url: `/ods-actions/upload-file/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // Portal files
    getPortalFiles: builder.mutation({
      query: (data) => ({
        url: `/portal_files?${encodeObjectToURIParams(data)}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadPortalFiles: builder.mutation({
      query: (data) => ({
        url: '/portal_files/upload',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openPortalFile: builder.mutation({
      query: ({ id }) => ({
        url: `/portal_files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deletePortalFile: builder.mutation({
      query: ({ id }) => ({
        url: `/portal_files/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // end of portal files module
    // Transaction files
    getTransactionFiles: builder.mutation({
      query: ({ id, type = '' }) => ({
        url: `/finance/transaction/files/list/${id}?file_type_id=${type}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadTransactionFile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/transaction/files/upload/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openTransactionFile: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/transaction/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteTransactionFile: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/transaction/files/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    // end of transaction files module
  }),
});

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    adminToken: null,
    user: null,
  },
  reducers: {
    setAdminToken(state, action) {
      state.adminToken = action.payload.token;
    },
    removeAdminToken(state) {
      state.adminToken = null;
    },
    setUser(state, action) {
      state.user = action.payload.user;
    },
  },
});

export const { actions, reducer } = sessionSlice;

export const { setAdminToken, removeAdminToken, setUser } = sessionSlice.actions;

export const {
  useLoginMutation,
  useGetFlightsListQuery,
  useGetOperatorListQuery,
  useGetAircraftTypeListQuery,
  useGetAirTypeListQuery,
  useGetCountryListQuery,
  useGetAircraftProfileListQuery,
  useGetAircraftProfileMutation,
  useStoreAircraftProfileMutation,
  useUpdateAircraftProfileMutation,
  useDeleteAircraftProfileMutation,
  useGetFlightMutation,
  useGetAircraftTypeMutation,
  useGetCrewNamesQuery,
  useCrewFlightTimeMutation,
  useStoreAircraftTypeMutation,
  useCreateOdsDefectMutation,
  useUpdateAircraftTypeMutation,
  useSendRosterMutation,
  useDeleteAircraftTypeMutation,
  useCreateUserMutation,
  useChangePasswordMutation,
  useUpdateUserMutation,
  useGetFuelPaymentsListQuery,
  useGetFuelPaymentMutation,
  useStoreFuelPaymentMutation,
  useCrewUploadImgMutation,
  useCrewUploadSingMutation,
  useGetServiceTypeListQuery,
  useUpdateFuelPaymentMutation,
  useUpdateCrewMutation,
  useOdsActionsMutation,
  useUpdateCrewDocMutation,
  useDeleteFuelPaymentMutation,
  useDeleteArtMaintenanceMutation,
  useGetOdsRecalculateMutation,
  useGetAircraftListQuery,
  useGetCompaniesListQuery,
  useGetCrewDocQuery,
  useGetCrewQuery,
  useUpdateOdsMutation,
  useGetOdsQuery,
  useGetOdsOtherQuery,
  useGetOdsStatusesQuery,
  useGetOdsAtaQuery,
  useGetOdsDefectsQuery,
  useGetOdsTocQuery,
  useGetCrewListByPostQuery,
  useGetCrewListWithParamsQuery,
  useCreateMaintenanceMutation,
  useGetFlightToggleStatusMutation,
  useGetFlightToggleCancelMutation,
  useGetFlightToggleInvoiceStatusMutation,
  useGetFlightTogglePermissionStatusMutation,
  useGetOdsByIdQuery,
  useGetFuelSuppliersListQuery,
  useReceiveFtlMutation,
  useGetFlightFuelAirportQuery,
  useGetFuelMethodsListQuery,
  // useGetFuelPriceQuery,
  useGetFewOdsFormsQuery,
  useGetExportExcelMutation,
  useGetFuelPriceExportExcelMutation,
  useCreateOdsFromFlightMutation,
  useGetOdsExportExcelMutation,
  useGetPersonsListQuery,
  useGetCargoListQuery,
  useGetAirportListQuery,
  useGetServiceListQuery,
  useGetSessionByAirportMutation,
  useGetPaymentByAirportQuery,
  useGetServicesByAirportQuery,
  useGetCrewListQuery,
  useGetOdsActionsQuery,
  useOdsActionsExportToExcelMutation,
  useGetOdsActionFilesMutation,
  useGetAircraftTypesListQuery,
  useGetFlightsScheduleQuery,
  useGetMainDeckListQuery,
  useConfirmDocumentMutation,
  useGetCrewSignatureQuery,
  useGetQuotationMutation,
  useGetCrewRosterQuery,
  useGetOdsPositionTypesQuery,
  useCreateOdsActionMutation,
  useCopyFlightMutation,
  useGetAllAircraftTypeQuery,
  useGetFuelBalanceQuery,
  useCreateOdsMutation,
  useGetDutyListQuery,
  useUpdateDutyListMutation,
  useCreateQuotationMutation,
  useUpdateQuotationMutation,
  useDownloadUserDocumentMutation,
  useCreateFlightFromFleetMutation,
  useCreateCrewDocMutation,
  useSendUserDocumentsMutation,
  useUploadCrewDocMutation,
  useGetFtlQuery,
  useGetFtlLogbookQuery,
  useGetQuotationsListQuery,
  useSendLogBookMutation,
  useGetFdpMutation,
  useDeleteQuotationMutation,
  useDeleteOdsActionsMutation,
  useDeleteCrewMutation,
  useDeleteCrewDocMutation,
  useGetOdsFormsByIdMutation,
  useGetCrewByIdQuery,
  useDeleteCrewRosterDutyMutation,
  useDeleteFlightsMutation,
  useCopyFleetMutation,
  useGetOperatorsTableListQuery,
  useGetOperatorMutation,
  useStoreOperatorMutation,
  useUpdateOperatorMutation,
  useDeleteOperatorMutation,
  useGetAirportTableListQuery,
  useGetAirportMutation,
  useStoreAirportMutation,
  useUpdateAirportMutation,
  useDeleteAirportMutation,
  useGetCaaTableListQuery,
  useGetCaaMutation,
  useStoreCaaMutation,
  useUpdateCaaMutation,
  useDeleteCaaMutation,
  useGetFuelPriceTableListQuery,
  useGetServiceSuppliersListMutation,
  useFuelBalanceMutation,
  useGetServiceSupplierQuery,
  useGetFuelPriceMutation,
  useStoreFuelPriceMutation,
  useGetUserDocumentsQuery,
  useGetUserDocumentsListQuery,
  useGetLogBookQuery,
  useUpdateFuelPriceMutation,
  useUpdateFuelPriceFieldMutation,
  useDeleteFuelPriceMutation,
  useGetFuelUpliftTableListQuery,
  useUpdateFuelUpliftMutation,
  useDeleteFuelUpliftMutation,
  useGetFuelUpliftExportExcelMutation,
  useGetCompanyTableListQuery,
  useChangeFeesMutation,
  useGetCompanyMutation,
  useStoreCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetSupplierTableListQuery,
  useGetSupplierMutation,
  useStoreSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useStoreFlightMutation,
  useUpdateFlightMutation,
  useExportFlightsToExcelMutation,
  useAddCrewMutation,
  useStoreOdsFromHopMutation,
  useSendQuotationMutation,
  useSendServiceMutation,
  useSendFuelOrderMutation,
  useGetPlaningFuelAirportSuppliersQuery,
  useUpdateFuelSuppliersMutation,
  useSendPermitMailMutation,
  useSendContractAndInvoiceMutation,
  useGetFlightContractMutation,
  useGetFlightInvoiceMutation,
  useSendCharterConfirmationMutation,
  useGetUsersTableQuery,
  useGetUserMutation,
  useDeleteUserMutation,
  useGetUserGroupsQuery,
  useGetPositionsQuery,
  useGetUsersPositionsTableQuery,
  useGetUserPositionsQuery,
  useDeleteUserPositionsMutation,
  useCreateUserPositionsMutation,
  useUpdateUserPositionsMutation,
  useGetUsersGroupsTableQuery,
  useDeleteUserGroupMutation,
  useGetUserGroupQuery,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useGetAtrMaintenanceQuery,
  useGetAtrMaintenanceWoHistoryQuery,
  useGetDetailMaintenanceMutation,
  useGetRefFileListMaintenanceMutation,
  useOpenRefFileMaintenanceMutation,
  useUploadRefFileMaintenanceMutation,
  useDeleteRefFileMaintenanceMutation,
  useCreateArtMaintenanceMutation,
  useUpdateArtMaintenanceMutation,
  useMaintenanceDownloadMutation,
  useGetAircraftBodyTableQuery,
  useGetUtilizationReportMutation,
  useExportToExcelUtilizationReportMutation,
  useGetWorkOrderQuery,
  useDeleteWorkOrderMutation,
  useConfirmDeleteWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useCreateWorkOrderMutation,
  useGetWorkOrderDetailMutation,
  useGetWorkOrderTaskQuery,
  useDeleteWorkOrderTaskMutation,
  useUpdateWorkOrderTaskMutation,
  useWorkOrderTaskDetailMutation,
  useGetWorkOrderTaskFileQuery,
  useDeleteWorkOrderTaskFileMutation,
  useDownloadWorkOrderTaskFileMutation,
  useUploadWorkOrderTaskFileMutation,
  useCreateWorkOrderPartsPairMutation,
  useValidateWorkOrderPartsPairMutation,
  useUpdateWorkOrderPartsPairMutation,
  useGetWorkOrderTaskPartsPairQuery,
  useDeleteWorkOrderTaskPartsPairMutation,
  useUpdateWorkOrderTaskPartsPairMutation,
  useCreateConsumableMutation,
  useGetConsumableListQuery,
  useDeleteConsumableMutation,
  useUpdateConsumableMutation,
  useCreateTaskToolsMutation,
  useGetTaskToolsListQuery,
  useDeleteTaskToolsMutation,
  useUpdateTaskToolsMutation,
  useDownloadCrewDocMutation,
  useGetTaskListQuery,
  useGetTaskListSearchQuery,
  useCreateWOTaskMutation,
  useAssignWOTaskMutation,
  useSearchWOTaskQuery,
  useGetPrintODSDataQuery,
  useGetAdditionalInvoicePdfMutation,
  useGetIrsPdfMutation,
  useGetViewPdfMutation,
  useWOTaskExportToExcelMutation,
  useGetWorkOrderFilesMutation,
  useGetAirListMutation,
  useGetStatisticByCompanyMutation,
  useGetStatisticDetailsByCompanyMutation,
  useGetStatisticByDepartureMutation,
  useGetStatisticByDestinationMutation,
  useGetStatisticBySectorMutation,
  useGetStatisticByProfitMutation,
  useGetBankListQuery,
  useCreateBankMutation,
  useGetBankInfoMutation,
  useUpdateBankMutation,
  useUpdateBankTransactionsCurrencyMutation,
  useDeleteBankMutation,
  useGetBankAccountNumbersListMutation,
  useGetBankAccountListQuery,
  useGetCardsListMutation,
  useGetBankInvoicesListQuery,
  useGetInvoiceTableQuery,
  useGetCurrencyListQuery,
  useGetDepartmentsListQuery,
  useGetFlightsQuery,
  useDeleteInvoiceMutation,
  useDeleteConfirmationMutation,
  useUpdateInvoiceMutation,
  useCreateInvoiceMutation,
  useGetDepartmentTableQuery,
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useUpdateDepartmentMutation,
  useCreateCurrencyMutation,
  useGetCurrencyTableQuery,
  useGetCurrencyQuery,
  useDeleteCurrencyMutation,
  useUpdateCurrencyMutation,
  useGetInvoiceMutation,
  useGetInvoiceExcelMutation,
  useCopyInvoiceMutation,
  useCreatePurposeMutation,
  useGetPurposesQuery,
  useGetPurposesTableQuery,
  useDeletePurposeMutation,
  useUpdatePurposeMutation,
  useCreateInvoiceDocMutation,
  useGetUserInvoiceRequestsQuery,
  useDownloadUserInvoiceMutation,
  useDeleteUserInvoiceMutation,
  useGetApproveInvoiceRequestsQuery,
  useApproveInvoiceMutation,
  useDeclineInvoiceMutation,
  useDownloadInvoiceMutation,
  useUpdateInvoiceByAccountMutation,
  useGetLoadedAirportsQuery,
  useGetAtrVersionByStatusQuery,
  useChangeAtrVersionMutation,
  useReturnToStoragePartMutation,
  useDownloadCrewSignaturesFileMutation,
  useGetOdsAutocompleteQuery,
  // training module
  useGetTrainingEventsCalendarQuery,
  useGetTrainingScheduleQuery,
  useGetTrainingAheadTableMutation,
  useGetProgramsReviewedTableMutation,
  useUpdateTrainingScheduleMutation,
  useCreateTrainingScheduleMutation,
  useDeleteTrainingScheduleMutation,
  useGetTrainingFlightCrewTableQuery,
  useGetTrainingFlightCrewDefaultDocumentsTableQuery,
  useDeleteTrainingFlightCrewDefaultDocumentsTableMutation,
  useGetTrainingFlightCrewDefaultBlockMutation,
  useCreateTrainingFlightCrewDefaultBlockMutation,
  useUpdateTrainingFlightCrewDefaultBlockMutation,
  // training module flight crew
  useGetTrainingPilotDataQuery,
  useUpdateTrainingPilotTagsMutation,
  useGetTrainingPilotDocsTableQuery,
  useCreateTrainingPilotDocsMutation,
  useUpdateTrainingPilotDocsTableMutation,
  useDeleteTrainingPilotDocsTableMutation,
  // pilot page docs
  useGetTRGFileMutation,
  useCreateTRGFileMutation,
  useDeleteTRGFileMutation,
  useGetFDBKFileMutation,
  useCreateFDBKFileMutation,
  useDeleteFDBKFileMutation,
  // training personnel table
  useGetInstructorTableQuery,
  useCreateInstructorMutation,
  useUpdateInstructorTableMutation,
  useDeleteInstructorRowMutation,
  // instructor page nested
  useGetInstructorDataQuery,
  useUpdateInstructorTagsMutation,
  useGetInstructorDocumentTableQuery,
  useCreateInstructorDocumentTableMutation,
  useUpdateInstructorDocumentTableMutation,
  useDeleteInstructorDocumentTableMutation,
  // instructor page files
  useGetInstructorDocMutation,
  useCreateInstructorDocMutation,
  useDeleteInstructorDocMutation,
  // training program
  useGetTrainingProgramBlockListQuery,
  useGetTrainingProgramTableQuery,
  useCreateTrainingProgramTableMutation,
  useUpdateTrainingProgramTableMutation,
  useDeleteTrainingProgramTableMutation,
  useGetTrainingProgramFilesMutation,
  useGetCrewDocumentFilesMutation,
  useUploadTrainingProgramFilesMutation,
  useUploadCrewDocumentsFilesMutation,
  useOpenTrainingFilesMutation,
  useDeleteTrainingFilesMutation,
  // slb file
  useGetTrainingProgramSLBMutation,
  useCreateTrainingProgramSLBMutation,
  useDeleteTrainingProgramSLBMutation,
  // fdbk file
  useGetTrainingProgramFDBKMutation,
  useCreateTrainingProgramFDBKMutation,
  useDeleteTrainingProgramFDBKMutation,
  // training program blocks
  useGetTrainingProgramBlocksTableQuery,
  useCreateTrainingProgramBlocksTableMutation,
  useUpdateTrainingProgramBlocksTableMutation,
  useDeleteTrainingProgramBlocksTableMutation,
  useGetUserListQuery,
  useGetMntAircraftTableQuery,
  useGetMntAircraftMutation,
  useUpdateMntAircraftMutation,
  useReadOdsMntAircraftMutation,
  // Dayli Availability
  useGetDailyAricraftDataQuery,
  useUpdateDailyAricraftDataMutation,
  useSendDailyEmailMutation,
  useGetDailyEmailListQuery,
  useCreateDailyEmailMutation,
  useDeleteDailyEmailMutation,
  useUpdateDailyEmailMutation,
  useGetCrewListWithParametersQuery,
  usePrintAllOdsMutation,
  // Notifications
  useGetNotificationListQuery,
  useReadNotificationMutation,
  useReadAllNotificationMutation,
  useOpenNotificationFileMutation,
  // Invoice Requests
  useUploadProofOfPaymentMutation,
  useGetProofOfPaymentMutation,
  useGetMyOrdersTableQuery,
  useTaskListSearchQuery,
  useStoreOrderMutation,
  useGetOrdersQuery,
  useGetOrdersTableInternalQuery,
  useGetOrdersTableQuery,
  useGetStorageOrdersTableQuery,
  useDeleteStorageOrderMutation,
  useUpdateStorageOrderMutation,
  useGetStoragePartsAutocompleteQuery,
  useDownloadPartCertificateMutation,
  useUploadPartCertificateMutation,
  useDeletePartCertificateMutation,
  useStorePartMutation,
  useUploadOdsActionFilesMutation,
  // Portal files
  useGetPortalFilesMutation,
  useUploadPortalFilesMutation,
  useOpenPortalFileMutation,
  useDeletePortalFileMutation,
  // Transaction files
  useGetTransactionFilesMutation,
  useUploadTransactionFileMutation,
  useOpenTransactionFileMutation,
  useDeleteTransactionFileMutation,
} = sessionApi;

export default sessionSlice.reducer;
