import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Card, CardContent, Chip, Grid, InputAdornment, TextField, Typography,
  useMediaQuery
} from '@mui/material';
import { Counter, Rename } from 'mdi-material-ui';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton/Button';
import { PART_TYPES, PRIORITY } from '../../../../constans/logistics';
import FormGroup from '../../../../form/components/FormGroup';
import Select from '../../../../form/components/Select';
import { useGetAircraftListQuery } from '../../../../store/session';
import { useGetInternalOrderQuery, useUpdateInternalOrderInfoMutation } from '../../../../store/slices/internalLogisticsSlice';
import { useGetOrderQuery, useGetUnitsListQuery, useUpdateOrderInfoMutation } from '../../../../store/slices/logisticsSlice';
import { getOptions } from '../../../../utils/getOptions';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import {
  GreenButton, RedButton,
  TextWithIcon
} from '../../style';
import { defaultValues, schema } from './schema';

const OrderPage = () => {
  const params = useParams();
  const location = useLocation();
  const partId = params.id;
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const tabValue = location?.pathname?.split('/')?.[3];
  const { data: currentOrder, isLoading: isOrderGet } = useGetOrderQuery({ orderId: partId }, {
    refetchOnMountOrArgChange: true,
    skip: tabValue === 'delivery'
  });
  const { data: internalOrder, isLoading: isInternalOrderGet } = useGetInternalOrderQuery({ orderId: partId }, {
    refetchOnMountOrArgChange: true,
    skip: tabValue === 'orders'
  });
  const { data: AircraftList, isLoading: isAircraftList } = useGetAircraftListQuery();
  const [updateOrder] = useUpdateOrderInfoMutation();
  const [updateInternalOrder] = useUpdateInternalOrderInfoMutation();
  const update = (tabValue === 'orders') ? updateOrder : updateInternalOrder;
  const { data: units, isLoading: isUnitsLoading } = useGetUnitsListQuery();
  const unitsOptions = getOptions(units);
  const AOGInternalPermission = usePermissions('internal_logistics_order_read_aog');
  const AOGPermission = usePermissions('logistics_order_read_aog');

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
    shouldFocusError: false
  });

  const optionsSelectStorage = [
    { value: 'hof', label: 'Hof' },
    { value: 'budapest', label: 'Budapest' },
    ...(AircraftList || []).map(item => ({
      value: item?.aircraft_registration.toLowerCase(), label: item?.aircraft_registration
    }))
  ];

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const sendData = {
      ...((tabValue === 'orders') && data),
      ...((tabValue === 'delivery') && { priority: data?.priority })
    };

    const res = await update({ id: partId, data: sendData });

    if (res?.error) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('The Order was update', {
      id: toastId,
    });
  };

  const setPriorityList = (list = []) => {
    if (tabValue === 'orders') {
      if (AOGPermission) {
        return list;
      }

      return list.filter((item) => item?.value !== 10);
    }
    if (AOGInternalPermission) {
      return list;
    }

    return list.filter((item) => item?.value !== 10);
  };

  useEffect(() => {
    if (tabValue === 'orders') {
      setValue('part_number', currentOrder?.part_number);
      setValue('part_name', currentOrder?.part_name);
      setValue('part_type', currentOrder?.part_type);
      setValue('part_count', currentOrder?.part_count);
      setValue('priority', currentOrder?.priority);
      setValue('unit_id', currentOrder?.unit_id);
    }
  }, [isOrderGet, isUnitsLoading]);

  useEffect(() => {
    if (tabValue === 'delivery') {
      setValue('part_number', 'fleet');
      setValue('part_name', 'fleet');
      setValue('part_type', 'fleet');
      setValue('part_count', 1);
      setValue('unit_id', 0);
      setValue('priority', internalOrder?.priority);
    }
  }, [isInternalOrderGet]);

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography noWrap variant="h4">
              Order #
              {partId}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <LinkButton path={`/logistics/my-orders/${tabValue}`} style={{ height: 'auto' }}>
              <Button title="Back" sx={{ ...RedButton, height: ' -webkit-fill-available' }}></Button>
            </LinkButton>
          </Box>

        </Box>
      </Card>
      <Card sx={{ mt: 3, padding: '1rem' }}>
        <Box>
          <Box sx={{ mb: 4 }}>
            <Typography noWrap variant="h5">
              Order Information
            </Typography>
          </Box>
          <Box>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography sx={TextWithIcon}>
                <b>Created at:</b>
                <Chip label={currentOrder?.created_at || internalOrder?.created_at} variant="outlined" sx={{ height: '28px' }} />
              </Typography>
            </Grid>
            {currentOrder?.update && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography sx={TextWithIcon}>
                <b>Updated at:</b>
                <Chip label={currentOrder?.update} variant="outlined" sx={{ height: '28px' }} />
              </Typography>
            </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography sx={TextWithIcon}>
                <b>Created by:</b>
                <Chip label={currentOrder?.created_by || internalOrder?.created_by} variant="outlined" sx={{ height: '28px' }} />
              </Typography>
            </Grid>
            {(currentOrder?.aircraft || internalOrder?.aircraft_reservation) && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography sx={TextWithIcon}>
                <b>Aircraft:</b>
                <Chip label={currentOrder?.aircraft || internalOrder?.aircraft_reservation} variant="outlined" sx={{ height: '28px' }} />
              </Typography>
            </Grid>
            )}
            {currentOrder?.storage && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography sx={TextWithIcon}>
                <b>Storage by:</b>
                <Chip label={optionsSelectStorage.find(item => item?.value === currentOrder?.storage)?.label} variant="outlined" sx={{ height: '28px' }} />
              </Typography>
            </Grid>
            )}
            {currentOrder?.wo_job_id && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography sx={TextWithIcon}>
                <b>Work Order Job:</b>
                <Chip label={currentOrder?.wo_job_id} variant="outlined" sx={{ height: '28px' }} />
              </Typography>
            </Grid>
            )}
          </Box>
        </Box>
      </Card>
      {(!isAircraftList && !isUnitsLoading && !isOrderGet) && (
      <Card sx={{ mt: 3, padding: '1rem' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              {/* <Grid item xs={3}>
                <FormGroup
                  label="Aircraft"
                  hasError={!!errors.aircraft_id}
                >
                  <Controller
                    control={control}
                    name="aircraft_id"
                    render={({ field }) => (
                      <Select
                        options={aircraftOptions}
                        placeholder="Aircraft"
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={4}>
                <FormGroup label="Storage" required hasError={!!errors.storage}>
                  <Controller
                    name="storage"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={optionsSelectStorage}
                        placeholder="Storage"
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={5}>
                <FormGroup label="Work Order Job">
                  <Controller
                    name="wo_job_id"
                    control={control}
                    render={({ fields }) => (
                      <ServerAutocomplete
                        fields={fields}
                        placeholder="Search Active Jobs"
                        disabled={!watch('aircraft_id')}
                        searchQueryFn={useTaskListSearchQuery}
                        queryParams={{ aircraft_id: watch('aircraft_id') }}
                      />
                    )}
                  />

                  <FieldError error={errors?.wo_job_id} />
                </FormGroup>
              </Grid> */}

              {(tabValue === 'orders') && (
              <Grid item xs={maxWidth800px ? 12 : 6}>
                <FormGroup
                  label="Part Number"
                  hasError={!!errors.part_number}
                >
                  <Controller
                    control={control}
                    name="part_number"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        placeholder="Part Number"
                        sx={maxWidth800px ? { width: '100%' } : {}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              )}

              {(tabValue === 'orders') && (
              <Grid item xs={maxWidth800px ? 12 : 2}>
                <FormGroup label="Part Type" required hasError={errors?.part_type}>
                  <Controller
                    name="part_type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={PART_TYPES}
                        placeholder="Part Type"
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              )}

              {(tabValue === 'orders') && (
              <Grid item xs={maxWidth800px ? 12 : 6}>
                <FormGroup
                  label="Part Name"
                  hasError={!!errors.part_name}
                >
                  <Controller
                    control={control}
                    name="part_name"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        placeholder="Part Name"
                        sx={maxWidth800px ? { width: '100%' } : {}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              )}

              <Grid item xs={maxWidth800px ? 12 : 2}>
                <FormGroup label="Priority" required hasError={errors.priority}>
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={setPriorityList(PRIORITY)}
                        placeholder="Priority"
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              {(tabValue === 'orders') && (
              <Grid item xs={maxWidth800px ? 12 : 6}>
                <FormGroup label="Quantity" required hasError={errors?.part_count}>
                  <Controller
                    name="part_count"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="Quantity"
                        size="small"
                        sx={maxWidth800px ? { width: '100%' } : {}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Counter sx={{ ...(field?.value && { color: '#626477' }) }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              )}

              {((tabValue === 'orders') && (watch('part_type') === 'consumable')) && (
                <Grid item xs={maxWidth800px ? 12 : 6}>
                  <FormGroup label="Unit" required hasError={errors?.unit_id}>
                    <Controller
                      name="unit_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={unitsOptions}
                          placeholder="Units"
                          disabled={isUnitsLoading}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              )}

            </Grid>
            <Grid>
              <Box>
                <Button
                  title="Save"
                  type="submit"
                  sx={{ ...GreenButton, mt: 4 }}
                />
              </Box>
            </Grid>
          </CardContent>
        </form>

      </Card>
      )}
    </>
  );
};

export default OrderPage;
