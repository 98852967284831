import {
  Box,
  Card,
  CircularProgress, Dialog, DialogContent, Divider, Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import React from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useGetInternalOrderAllInfoQuery } from '../../../../store/slices/internalLogisticsSlice';
import { useGetOrderAllInfoQuery } from '../../../../store/slices/logisticsSlice';
import { headerBtn } from '../../../Invoices/style';
import { DetailsGridStyle } from '../../style';
import MainInfo from './MainInfo/MainInfo';
import OrderDocs from './OrderDocs/OrderDocs';
import OrderHistory from './OrderHistory/OrderHistory';
import PartPath from './PartPath/PartPath';
import TimelinePart from './TimelinePart/TimelinePart';

const OrderInfoModal = (props) => {
  const { open, onClose } = props;
  const params = useParams();
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const location = useLocation();
  const tabValue = location.pathname.split('/')[3];
  const { data: externalOrderInfo, isLoading: isExternalInfoLoading } = useGetOrderAllInfoQuery({ 
    id: params?.id
  }, {
    refetchOnMountOrArgChange: true,
    skip: (tabValue === 'delivery') || !params?.id
  });
  const { data: internalOrderInfo, isLoading: isInternalInfoLoading } = useGetInternalOrderAllInfoQuery({ 
    id: params?.id
  }, {
    refetchOnMountOrArgChange: true,
    skip: (tabValue === 'orders') || !params?.id
  });

  const orderInfo = externalOrderInfo?.main?.order_number?.includes(params?.id) ? externalOrderInfo : internalOrderInfo;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      {(isExternalInfoLoading || isInternalInfoLoading) ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={maxWidth800px ? 12 : 8.5} sx={{ backgroundColor: '#e9f1fe26' }}>
            <Card
              sx={{ padding: '1rem' }}
            >
              <Box sx={headerBtn}>
                <Box>
                  <Typography variant="h5" noWrap>
                    Order 
                    {' '}
                    {orderInfo?.main?.order_number}
                  </Typography>
                </Box>
              </Box>

              <Divider />
            </Card>

            {/* Main Info */}
            <MainInfo main={orderInfo?.main} orderInfo={orderInfo} />
              
            {/* Parts path */}
            <PartPath path={orderInfo?.path} />

            {/* Timeline part */}
            <TimelinePart timeline={orderInfo?.timeline} />

            {/* Docs */}
            {(orderInfo?.invoices?.length >= 1) && <OrderDocs invoices={orderInfo?.invoices} />}
            
          </Grid>

          <Grid item xs={maxWidth800px ? 12 : 3.5} sx={DetailsGridStyle}>

            {/* Order History */}
            <OrderHistory order={orderInfo || {}} />
          </Grid>
        </Grid>
      )}
      
    </Dialog>
  );
};

export default OrderInfoModal;