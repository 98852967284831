import EditIcon from '@mui/icons-material/Edit';
import {
  Box, Card,
  Divider,
  Tab, Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import EditableTable from '../../../components/EditableTable';
import LinkButton from '../../../components/LinkButton';
import Loader from '../../../components/Loader/ContentPageLoader';
import {
  useCreateTrainingProgramTableMutation,
  useDeleteTrainingProgramTableMutation,
  useGetTrainingProgramTableQuery,
  useUpdateTrainingProgramTableMutation
} from '../../../store/session';
import { setFilesName } from '../../../utils/constants/portalFiles';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import FileDrawer from '../FileDrawer/FileDrawer';
import { getRowStyle, hightlightTab } from '../FlightCrew/PilotPage/PilotPage';
import ProgramForm from './ProgramForm/ProgramForm';
import { columns } from './columns';
import {
  PilotContainer,
  TitleContainer,
  tableContainer,
} from './styles';
import { useTrainingProgram } from './useTrainingProgram';

const TrainingProgram = () => {
  const {
    pilotId,
    useGetTrainingProgramBlockListQuery,
    refetch,
    useRefetch,
    toast,
    hasPermissionsOdsCreate,

    getFileList,
    updateFiles,
    isAttachLoading,
    isLoadingTrainingFiles
  } = useTrainingProgram();

  const { data: trainingBlocksData, isLoading, refetch: refetchTrainingBlocks } = useGetTrainingProgramBlockListQuery(pilotId);
  const [currentBlock, setCurrentBlock] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const hasPermissions = usePermissions('training_module_write');

  useEffect(() => {
    if (trainingBlocksData && trainingBlocksData?.length && !currentBlock) {
      setCurrentBlock(trainingBlocksData[0].id);
    }
  }, [isLoading, trainingBlocksData]);

  const handleChangeBlock = (_, newValue) => {
    setCurrentBlock(newValue);
  };

  const [createDocument] = useCreateTrainingProgramTableMutation();

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const formData = new FormData();

    formData.append('program_name', data.program_name);
    formData.append('review_date', data.review_date ? moment(data.review_date).format('YYYY-MM-DD') : '');
    formData.append('threshold_days', data.threshold_days || (data?.threshold_days?.toString() === '0' ? '0' : ''));
    formData.append('feedback', data.feedback);
    formData.append('file', data.file);
    formData.append('block_id', currentBlock);

    const res = await createDocument(formData);

    if (res.error) {
      toast.error(res.error?.data?.message || 'Something went wrong.', {
        id: toastId,
      });

      return;
    }
    useRefetch();
    toast.success('Success!', {
      id: toastId,
    });
    setOpenDrawer(false);
  };

  if ( isLoading || !trainingBlocksData || (!currentBlock && trainingBlocksData?.length)) return <Loader />;

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, type, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  return (
    <>
      <LinkButton path="/training-module">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{ padding: '1rem', mt: 3 }}>

        <Box sx={TitleContainer}>
          <Box sx={PilotContainer}>
            {/* <Paper sx={paperPilot} elevation={4}> */}
            <Typography variant="h3" sx={{ display: 'block' }}>
              Training Programs
            </Typography>
            {/* </Paper> */}
          </Box>
          <Button sx={{ mr: '15px' }} disabled={!currentBlock || !hasPermissions} title="Create New" onClick={() => setOpenDrawer(true)} />

        </Box>

        <Divider sx={{ mt: 5 }} />
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          gap: 20,
          padding: 3,
          pb: 0
        }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'baseline',
            gap: 0,
          }}
          >
            {trainingBlocksData?.length ? (
              <Tabs
                value={currentBlock}
                onChange={handleChangeBlock}
                variant="scrollable"
                scrollButtons="auto"
              >
                {trainingBlocksData.map((block) => (
                  <Tab
                    label={hightlightTab(block)}
                    key={block.id}
                    value={block.id}
                  />
                ))}
              </Tabs>
            ) : null}
            <Box>
              {hasPermissions ? (
                <LinkButton path="/training-module/training-programs/blocks">
                  <Button disabled={!hasPermissions} size="small" startIcon={<EditIcon />} title="Manage Blocks" />
                </LinkButton>
              ) : (
                <Button disabled size="small" startIcon={<EditIcon />} title="Manage Blocks"></Button>
              )}
            </Box>
          </Box>

        </Box>

        <Box sx={tableContainer}>

          <EditableTable
            getRowId={(row) => row.id}
            useHook={currentBlock ? useGetTrainingProgramTableQuery : undefined}
            useHookUpdate={useUpdateTrainingProgramTableMutation}
            useHookDelete={useDeleteTrainingProgramTableMutation}
            tableParams={{
              block_id: currentBlock,
            }}
            getRowHeight={() => 'auto'}
            sortByType="ASC"
            sortByActive="program_name"
            getRowClassName={({ row }) => `row-${getRowStyle(row)}`}
            columns={columns}
            hasPermissions={hasPermissions}
            experimentalFeatures={{ columnGrouping: true }}
            handleFileDrawer={handleFileDrawer}
            refetchTable={refetch}
            refetchCallback={refetchTrainingBlocks}
          />

        </Box>

      </Card>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          ...(maxWidth800px && {
            '& .MuiPaper-elevation': {
              width: '100vw'
            },
            '& .MuiPaper-rounded': {
              width: 'auto'
            }
          })
        }}
      >
        <ProgramForm onSubmit={onSubmit} maxWidth800px={maxWidth800px} />
      </Drawer>

      <FileDrawer
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name}
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList}
        uploadFile={(file) => updateFiles(fileDrawerState.currentId, file, fileDrawerState.type, () => handleFileDrawer(true, fileDrawerState.currentId, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
        isAttachLoading={isAttachLoading}
        hasPermissions={hasPermissionsOdsCreate || hasPermissions}
        isLoadingFiles={isLoadingTrainingFiles}
        deleteFileCallback={useRefetch}
      />
    </>
  );
};

export default TrainingProgram;
