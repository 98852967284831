import {
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,
  useMediaQuery
} from '@mui/material';
import React, { useEffect } from 'react';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';

const SuppliersForm = (props) => {
  const {
    supplierFields,
    Controller,
    control,
    watch,
    setValue,
    errors,
    trigger,
    getValues,
    supplierRemove,
  } = props;

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  // const addNewsupplier = async () => {
  //   const suppliers = getValues('service_suppliers');
  //   const isValid = await trigger();
  //
  //   if (isValid) {
  //     const NewSupplier = {
  //
  //       supplier_name: '',
  //       supplier_phone: '',
  //       supplier_email: '',
  //       supplier_skype: '',
  //
  //     };
  //
  //     const suppliersItems = [...suppliers, NewSupplier];
  //
  //     setValue('suppliers', suppliersItems);
  //   }
  // };

  useEffect(() => {
    const subscription = watch(async(value, { name, type }) => {
      const formValues = getValues();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Grid container spacing={3} rowSpacing={2} sx={{ flexGrow: 1 }}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {supplierFields.map((item, index) => (
                <TableRow
                  key={item.id}
                  sx={maxWidth800px ? {
                    display: 'flex',
                    flexDirection: 'column',
                  } : {}}
                >
                  <TableCell sx={maxWidth800px ? {
                    pl: '0!important'
                  } : {}}
                  >
                    <FormGroup required hasError={errors?.service_suppliers?.[index]?.srvsup_name}>
                      <Controller
                        name={`service_suppliers.${index}.srvsup_name`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            label="Name"
                            disabled
                            placeholder="Name"
                          />
                        )}
                      />
                      <FieldError error={errors?.service_suppliers?.[index]?.srvsup_name} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup required hasError={errors?.service_suppliers?.[index]?.srvsup_phone}>
                      <Controller
                        name={`service_suppliers.${index}.srvsup_phone`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            label="Phone"
                            placeholder="Phone"
                          />
                        )}
                      />
                      <FieldError error={errors?.service_suppliers?.[index]?.srvsup_phone} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup hasError={errors?.service_suppliers?.[index]?.srvsup_email}>
                      <Controller
                        name={`service_suppliers.${index}.srvsup_email`}
                        control={control}
                        disabled
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            type="email"
                            label="Email"
                            placeholder="Email"
                          />
                        )}
                      />
                      <FieldError error={errors?.service_suppliers?.[index]?.srvsup_email} />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup hasError={errors?.service_suppliers?.[index]?.services}>
                      <Controller
                        name={`service_suppliers.${index}.services`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            variant="standard"
                            disabled
                            placeholder="Services"
                            label="Services"
                          />
                        )}
                      />
                      <FieldError error={errors?.service_suppliers?.[index]?.services} />
                    </FormGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SuppliersForm;
