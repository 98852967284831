import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Checkbox, Divider, Grid,
  InputLabel,
  ToggleButton, ToggleButtonGroup, Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ApproveModal from '../../../../../../components/ApproveModal';
import Button from '../../../../../../components/Button';
import Drawer from '../../../../../../components/Drawer';
import InBoxIcon from '../../../../../../components/Icons/InBoxIcon';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import Select from '../../../../../../form/components/Select';
import { useCreateWorkOrderPartsPairMutation, useUpdateWorkOrderPartsPairMutation, useValidateWorkOrderPartsPairMutation } from '../../../../../../store/session';
import AutocompleteForm from './components/AutocompleteForm';
import AutocompletePartOffForm from './components/AutocompletePartOffForm';
import ManualForm from './components/ManualForm';
import ManualPartOffForm from './components/ManualPartOffForm';
import { defaultValues, schema } from './schema';
import { cancelSx, drawer, footerSx } from './styles';

const CreatePartsPairDrawer = ({
  open, onClose, optionsCrewList, currentParts = null, workOrderTask, addPartCallback = () => {}, isToOds, partOffTableState = []
}) => {
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const params = useParams();
  const [currentPart, setCurrentPart] = useState(null);
  const [currentPartSerial, setCurrentPartSerial] = useState(null);
  const [currentPartNumber, setCurrentPartNumber] = useState(null);
  const [currentPartIDOff, setCurrentPartIDOff] = useState(null);
  const [currentPartNameOff, setCurrentPartNameOff] = useState(null);
  const [sameUnit, setSameUnit] = useState(false);
  const [currentPartSNOff, setCurrentPartSNOff] = useState(null);
  const [openApproveModal, setOpenApproveModal] = useState({
    isOpen: false,
    data: null
  });
  const [partOnType, setPartOnType] = useState('autocomplete');
  const [partOffType, setPartOffType] = useState('autocomplete');
  const [createWorkOrderPartsPair, { isLoading }] = useCreateWorkOrderPartsPairMutation();
  const [updateWorkOrderPartsPair, { isLoading: isUpdateLoading }] = useUpdateWorkOrderPartsPairMutation();
  const [validateWorkOrderPartsPair, { isLoading: isValidateLoading }] = useValidateWorkOrderPartsPairMutation();
  const isOpenApproveModal = ((!!currentPartSerial?.data?.aircraft_id && !!workOrderTask?.aircraft_id) && (currentPartSerial?.data?.aircraft_id === workOrderTask?.aircraft_id));

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
    setValue,
    trigger,
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (!currentParts && isToOds && partOffTableState?.some(pt => (
      (((((!!pt?.part_id || !!data?.part_id)) && (pt?.part_id === data?.part_id))
        || (((!!pt?.serial_on_number || !!data?.serial_on_number) && (!!pt?.part_on_number || !!data?.part_on_number)) && ((pt?.serial_on_number === data?.serial_on_number) && (pt?.part_on_number === data?.part_on_number)))
        || (((!!pt?.serial_off_number || !!data?.serial_off_number) && (!!pt?.part_off_number || !!data?.part_off_number))) && (((pt?.serial_off_number === data?.serial_off_number) && (pt?.part_off_number === data?.part_off_number))) 
        || ((!!pt?.part_aircraft_body_id || !!data?.part_aircraft_body_id) && (pt?.part_aircraft_body_id === data?.part_aircraft_body_id))
      ))))) {
      toast.error('This part has already been added!', {
        duration: 4000
      });

      return;
    }

    let toastId;

    if (!isToOds) {
      toastId = toast.loading('Loading...');
    }

    const formData = new FormData();
    let res;

    const sendData = {
      ...data,
      part_aircraft_body_id: !data?.part_aircraft_body_id ? '' : data?.part_aircraft_body_id,
      part_id: data?.part_id === 0 ? '' : data?.part_id,
      same_unit: sameUnit
    };

    if (sendData?.tabValue === 'autocomplete') {
      delete sendData.certificate;
      delete sendData.unit_id;
    }

    if ((sendData?.tabValue === 'manual') && (sendData.certificate === null)) {
      delete sendData.certificate;
    }

    delete sendData.tabValue;
    delete sendData.tabOffValue;

    Object.entries(sendData).forEach(([key, value]) => {
      if (!value) return;
      
      if (key === 'certificate') {
        formData.append(key, data.certificate);
      } else {
        formData.append(key, value || null);
      }
    });
    if (!isToOds) {
      if (!currentParts) {
        res = await createWorkOrderPartsPair(formData);
      } else {
        formData.delete('wo_task_id');
        formData.delete('part_aircraft_body_id');
        formData.delete('serial_off_number');
        formData.delete('part_off_number');

        res = await updateWorkOrderPartsPair({ data: formData, id: currentParts?.id });
      }
    } else {
      formData.delete('wo_task_id');
      if (!currentParts) res = await validateWorkOrderPartsPair(formData);
    }
    if (res?.error) {
      toast.error(res.error.data?.message || 'Something went wrong.', {
        id: toastId,
      });

      return;
    }

    addPartCallback(sendData, !!currentParts);
    onClose();
    reset();

    if (!isToOds) { toast.success('Successfully created!', {
      id: toastId,
    }); }
  };

  const handleSelectPart = (item, type = '') => {
    if (type === 'id') {
      setCurrentPart(item);
      setCurrentPartSerial(null);
      setCurrentPartNumber(null);
    }
    if (type === 'serial') {
      setCurrentPart(null);
      setCurrentPartSerial(item);
      setCurrentPartNumber(null);
    }
    if (type === 'number') {
      setCurrentPartNumber(item);
      setCurrentPart(null);
      setCurrentPartSerial(null);
    }

    setValue('part_id', item?.value);
    setValue('part_on_number', item?.data?.part_number);
    setValue('serial_on_number', item?.data?.serial_number === 'N/A' ? item?.data?.quantity : item?.data?.serial_number);
    setValue('part_name', item?.data?.part_name);
    setValue('storage', item?.data?.storage);
    setValue('quantity', item?.data?.quantity);
    trigger('part_id', 'part_on_number', 'serial_on_number', 'part_name', 'storage');
  };

  const handleSelectAircraftPart = (item, type) => {
    if (type === 'part_id') {
      setCurrentPartIDOff(item);
      setCurrentPartNameOff(null);
      setCurrentPartSNOff(null);
    }
    if (type === 'part_name') {
      setCurrentPartIDOff(null);
      setCurrentPartNameOff(item);
      setCurrentPartSNOff(null);
    }
    if (type === 'serial_number') {
      setCurrentPartIDOff(null);
      setCurrentPartNameOff(null);
      setCurrentPartSNOff(item);
    }

    setValue('part_aircraft_body_id', item?.value);
    setValue('part_off_number', item?.data?.part_number);
    setValue('part_off_name', item?.data?.part_name);
    setValue('serial_off_number', item?.data?.serial_number || item?.data?.quantity);
    trigger('part_aircraft_body_id', 'part_off_number', 'serial_off_number');
  };

  const handleChangePartOnType = (type) => {
    setCurrentPart(null);
    setCurrentPartSerial(null);
    setCurrentPartNumber(null);
    setValue('tabValue', type);
    setValue('storage', '');
    setValue('part_id', 0);
    setValue('quantity', 1);
    setValue('part_name', '');
    setValue('part_on_number', '');
    trigger('part_on_text');
    setPartOnType(type);
  };

  const handleChangePartOffType = (type) => {
    setCurrentPartIDOff(null);
    setCurrentPartNameOff(null);
    setCurrentPartSNOff(null);
    setValue('tabOffValue', type);
    if (!currentParts) {
      setValue('part_off_number', '');
      setValue('serial_off_number', '');
      setValue('part_off_name', '');
    }
    setValue('part_aircraft_body_id', 0);
    setPartOffType(type);
  };

  const handleCloseApproveModal = () => {
    setOpenApproveModal({
      isOpen: false,
      data: null
    });
  };

  useEffect(() => {
    if (open && params?.id) {
      reset();
      setCurrentPartIDOff(null);
      setCurrentPartNameOff(null);
      setCurrentPartSNOff(null);
      setCurrentPart(null);
      setCurrentPartSerial(null);
      setCurrentPartNumber(null);
      setSameUnit(false);
      setValue('wo_task_id', params?.id);
      setPartOnType('autocomplete');
      setPartOffType('autocomplete');
    }
  }, [open, params?.id]);

  useEffect(() => {
    if (open && currentParts) {
      setValue('part_off_number', currentParts?.part_off_number);
      setValue('part_off_name', currentParts?.part_off_name);
      setValue('part_off_text', currentParts?.part_off_text);
      setValue('performed_by', currentParts?.performed_by);
      setValue('serial_off_number', currentParts?.serial_off_number);
      setPartOffType('manual');
      setValue('tabOffValue', 'manual');

      if (currentParts?.part_on_text) setValue('part_on_text', currentParts?.part_on_text);
    }
  }, [open, currentParts]);

  return (
    <Drawer
      title={currentParts ? 'Edit' : isToOds ? 'Add Part Off & Part On' : 'Task'}
      open={open}
      onClose={onClose}
      sx={{ ...(maxWidth800px && drawer) }}
    >
      <form>
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          sx={{ p: '30px' }}
        >
          <Grid item xs={12}>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'capitalize', display: 'flex', gap: '15px', alignItems: 'center'
                }}
              >
                Part Off
                <InBoxIcon />
              </Typography>
              {!currentParts && (
                <Box>
                  <ToggleButtonGroup
                    value={partOffType}
                    exclusive
                    onChange={(e) => handleChangePartOffType(e.target.value)}
                    size="small"
                    aria-label="Small sizes"
                    sx={{
                      mr: 2,
                      '& .Mui-selected': {
                        backgroundColor: '#dfe6f4'
                      }
                    }}
                  >
                    <ToggleButton value="autocomplete" aria-label="module">
                      Autocomplete
                    </ToggleButton>
                    <ToggleButton value="manual" aria-label="list">
                      Manual
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}
            </Box>

            <Divider />
          </Grid>

          {(partOffType === 'autocomplete') && (
            <AutocompletePartOffForm
              errors={errors}
              control={control}
              handleSelectAircraftPart={handleSelectAircraftPart}
              currentPartIDOff={currentPartIDOff}
              currentPartNameOff={currentPartNameOff}
              currentPartSNOff={currentPartSNOff}
              currentParts={currentParts}
            />
          )}

          {(partOffType === 'manual') && (
            <ManualPartOffForm
              control={control}
              errors={errors}
              currentPartIDOff={currentPartIDOff}
              currentPartNameOff={currentPartNameOff}
              currentPartSNOff={currentPartSNOff}
              currentParts={currentParts}
            />
          )}

          <Grid item xs={12}>
            <FormGroup label="Part Off Text" required hasError={errors?.part_off_text}>
              <Controller
                name="part_off_text"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Part Off Text"
                    multiline
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'capitalize', display: 'flex', gap: '15px', alignItems: 'center'
                }}
              >
                Part On
                <InBoxIcon />
              </Typography>
              <Box>
                <ToggleButtonGroup
                  value={partOnType}
                  exclusive
                  onChange={(e) => handleChangePartOnType(e.target.value)}
                  size="small"
                  aria-label="Small sizes"
                  sx={{
                    mr: 2,
                    '& .Mui-selected': {
                      backgroundColor: '#dfe6f4'
                    }
                  }}
                >
                  <ToggleButton value="autocomplete" aria-label="module">
                    Autocomplete
                  </ToggleButton>
                  <ToggleButton value="manual" aria-label="list">
                    Manual
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>

            <Divider />
          </Grid>

          {(partOnType === 'autocomplete') && (
            <AutocompleteForm
              errors={errors}
              control={control}
              handleSelectPart={handleSelectPart}
              currentPart={currentPart}
              currentPartNumber={currentPartNumber}
              currentPartSerial={currentPartSerial}
              setValue={setValue}
              handleChangePartOnType={handleChangePartOnType}
            />
          )}

          {(partOnType === 'manual') && (
            <ManualForm
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )}

          <Grid item xs={12}>
            <FormGroup label="Part On Text" required hasError={errors?.part_on_text}>
              <Controller
                name="part_on_text"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows={2}
                    placeholder="Enter Part On Text"
                    multiline
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
              Performed By
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FormGroup label="Performed By" required hasError={!!errors.performed_by}>
              <Controller
                render={({ field } ) => (
                  <Select
                    options={optionsCrewList}
                    placeholder="Performed By"
                    {...field}
                  />
                )}
                control={control}
                name="performed_by"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Box sx={footerSx}>
          <Button
            disabled={isLoading || isUpdateLoading || isValidateLoading}
            onClick={handleSubmit((data) => {
              if (isOpenApproveModal) {
                setOpenApproveModal({
                  isOpen: true,
                  data
                });
              } else {
                onSubmit(data);
              }
            })}
            variant="contained"
            title={currentParts ? 'Save' : 'Create'}
          />
          <Button sx={cancelSx} title="Close" onClick={onClose} />
          <Box>
            <InputLabel sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Checkbox
                value={sameUnit}
                onChange={(el) => {
                  setSameUnit(el.target.checked);
                }}
              />
              <Typography sx={{ textTransform: 'uppercase' }}>Same Unit</Typography>
              
            </InputLabel>
           
          </Box>
        </Box>

        <ApproveModal
          open={openApproveModal.isOpen}
          onClose={handleCloseApproveModal}
          data={{
            text: `The spare part is already installed on the ${currentPartSerial?.data?.aircraft_reservation}, are you sure you want to make this entry?`,
            data: openApproveModal.data,
            successButton: 'Send'
          }}
          onApprove={({ data }) => {
            handleCloseApproveModal();
            onSubmit(data);
          }}
          refetch={() => {}}
        />
      </form>
    </Drawer>
  );
};

export default CreatePartsPairDrawer;
