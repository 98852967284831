import NotificationsIcon from '@mui/icons-material/Notifications';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { HeaderName, StyledPencil } from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton/Button';
import { GreenButton, TextWrap } from '../../Logistics/style';

export const columns = ({
  handleOpenFile,
  handleOpenNotificationDrawer,
  handleOpenUpdatePaidModal,
  hasPermission,
}) => ([
  {
    flex: 0.1,
    field: 'actions',
    maxWidth: 60,
    headerName: 'Actions',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}></Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <StyledPencil disabled={!hasPermission} onClick={() => handleOpenUpdatePaidModal(row)} />
        {
          row?.status === 'unpaid' ? (
            <NotificationsIcon
              sx={{
                color: '#FDB528',
                '&:hover': {
                  cursor: 'pointer',
                  color: '#ffc14e !important',
                }
              }}
              disabled={!hasPermission}
              onClick={() => handleOpenNotificationDrawer(row?.flight_id, row?.broker_email, row?.notifications_data, row?.id)}
            />
          ) : <></>
        }
      </Box>
    ),
  },
  {
    flex: 0.1,
    field: 'flight_number',
    minWidth: 140,
    headerName: 'Flight Number',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>Flight Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box sx={{
        textDecoration: 'underline'
      }}
      >
        <LinkButton path={`/flights/${row.flight_id}`}>
          <Typography>{`#${row?.flight_number}`}</Typography>
        </LinkButton>
      </Box>
    ),
  }, // Flight Number
  {
    flex: 0.15,
    field: 'invoice_number',
    minWidth: 140,
    headerName: 'Invoice Number',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.invoice_number}</Typography>
      </Box>
    ),
  }, // Invoice Number
  {
    // flex: 0.2,
    field: 'invoice',
    minWidth: 140,
    headerName: 'Invoice',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoice</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <PictureAsPdfIcon sx={GreenButton} onClick={() => handleOpenFile(row.id)} />
      </Box>
    ),
  }, // Invoice
  {
    flex: 0.15,
    field: 'route',
    minWidth: 140,
    headerName: 'Route',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Route</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.route}</Typography>
      </Box>
    )
  }, // Route
  {
    flex: 0.2,
    field: 'broker',
    minWidth: 120,
    headerName: 'Broker',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Broker</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.broker}</Typography>
      </Box>
    ),
  }, // Broker
  {
    flex: 0.2,
    field: 'broker_email',
    minWidth: 140,
    headerName: 'Broker Email',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Broker Email</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>{row?.broker_email}</Typography>
      </Box>
    ),
  },
  {
    // flex: 0.2,
    field: 'reg',
    minWidth: 120,
    headerName: 'REG',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>REG</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.reg}</Typography>
      </Box>
    )
  }, // REG
  {
    // flex: 0.2,
    field: 'price',
    minWidth: 120,
    headerName: 'Price',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Price</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.price}</Typography>
      </Box>
    )
  }, // Price
  {
    flex: 0.1,
    field: 'day_of_invoice',
    minWidth: 120,
    headerName: 'Day of Invoice',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Day of Invoice</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.day_of_invoice}</Typography>
      </Box>
    )
  }, // Day of Invoice
  {
    // flex: 0.2,
    field: 'due_date',
    minWidth: 120,
    headerName: 'Due Date',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Due Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.due_date}</Typography>
      </Box>
    )
  }, // REG
  {
    // flex: 0.2,
    field: 'status',
    minWidth: 120,
    headerName: 'Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>
          {row?.status === 'paid' && (<Chip label="Paid" color="success" variant="outlined" size="small" sx={{ fontWeight: 800 }} />)}
          {row?.status === 'unpaid' && (<Chip label="Unpaid" color="error" variant="outlined" size="small" sx={{ fontWeight: 800 }} />)}
          {row?.status === 'canceled' && (<Chip label="Canceled" color="warning" variant="outlined" size="small" sx={{ fontWeight: 800 }} />)}
        </Typography>
      </Box>
    )
  }, // Status
  {
    // flex: 0.2,
    field: 'paid',
    minWidth: 120,
    headerName: 'Paid',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Paid</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.paid}</Typography>
      </Box>
    )
  }, // Paid
  {
    flex: 0.25,
    field: 'day_of_payment',
    minWidth: 110,
    headerName: 'Day of Payment',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Day of Payment</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography>{row?.day_of_payment}</Typography>
      </Box>
    )
  }, // Day of Payment
]);
