import {
  Box,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import React from 'react';
import { headerBtn, headerBtnS } from '../../../../Invoices/style';
import { Item, TextWithIcon } from '../../../../Logistics/style';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import { maxWidth1500px } from '../../../../../constans/mediaQuery';
import { StatusText } from '../../../../Logistics/OrdersDashboard/styles';
import { CardAction } from '../../styles';

const DashboardInvoiceCard = ({ props, invoice, onOpen }) => {
  const formattedInvoice = props.formatInvoice(invoice);
  let background = (formattedInvoice.status !== 3 && formattedInvoice.is_expired) ? '#ffe4e4' : null;
  if (formattedInvoice.status === 1) {
    background = '#fff7e4';
  }

  return (
    <Item onClick={() => onOpen(formattedInvoice.id)} sx={{ background }}>
      <CardContent sx={{ position: 'relative' }}>
        <Box sx={{
          ...headerBtn, ...(maxWidth1500px() && headerBtnS), display: 'flex', justifyContent: 'space-between'
        }}
        >
          <Box>
            <Typography noWrap variant="subtitle1" sx={StatusText}>
              {formattedInvoice.number}
            </Typography>
          </Box>

          <Box sx={TextWithIcon}>
            {formattedInvoice.type.icon}
            {formattedInvoice.type.label}
          </Box>
        </Box>

        <Divider />

        <Box>
          <Typography noWrap variant="body1">
            <b>Amount:</b>
            {' '}
            {formattedInvoice.amount}
          </Typography>
          {(formattedInvoice.due_date && (
            <Typography noWrap variant="body1">
              <b style={{ color: '#1c4675' }}>Due date:</b>
              {' '}
              {formattedInvoice.due_date}
            </Typography>
          ))}
          {(formattedInvoice.purpose && (
            <Typography noWrap variant="body1">
              <b>Purpose:</b>
              {' '}
              {formattedInvoice.purpose}
            </Typography>
          ))}
          <Typography noWrap variant="body1">
            <b>Sender:</b>
            {' '}
            {formattedInvoice.sender}
          </Typography>
        </Box>

        <Divider />

        <Box
          sx={CardAction}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.handleDownloadInvoice({ id: invoice.id, file_name: invoice.file_name });
          }}
        >
          <DownloadIcon />
          Download Invoice
        </Box>
      </CardContent>
    </Item>
  );
};

export default DashboardInvoiceCard;
