import { Grid, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Select from '../../../../../../form/components/Select';
import { content } from '../../../../../Banks/style';
import { headerBtn } from '../../../../../Invoices/style';
import { SCORES } from '../../constants';

export const MarkersItems = ({
  onChangeCallback,
  markers,
  form: {
    watch,
    setValue,
    control,
    formState: { errors }
  },
  name,
  counter,
  maxWidth800px = false
}) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Box sx={content}>
        {markers?.fields?.map((item, index) => (
          <Grid
            container
            sx={{
              display: 'flex',
              backgroundColor: index === 0 ? '#eff1fc' : 'whitesmoke',
              padding: '8px',
              borderRadius: '8px',
              justifyContent: 'flex-start'
            }}
            key={item.id}
          >
            <Grid item xs={12} lg={12}>
              <Box sx={{ ...(headerBtn), justifyContent: 'space-between', display: 'flex' }}>
                <Typography noWrap variant="h6" sx={{ ...(index === 0 && { fontWeight: 'bold' }) }}>
                  {index === 0 && counter}
                  {' '}
                  {item.marker_name}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={maxWidth800px ? 12 : 6}>
              <FormGroup label="Mark for comments" hasError={!!errors?.name?.[index]?.comments}>
                <Controller
                  name={`${name}.${[index]}.comments`}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={() => {
                        setValue(`${name}.${[index]}.comments`, !watch(`${name}.${[index]}.comments`));
                      }}
                    />
                  )}
                />

                <FieldError error={errors?.name?.[index]?.comments} />
              </FormGroup>
            </Grid>

            {index === 0 && (
              <Grid item xs={maxWidth800px ? 12 : 6}>
                <FormGroup label="Score:" hasError={!!errors?.name?.[index]?.score}>
                  <Controller
                    name={`${name}.${[index]}.score`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={SCORES}
                        placeholder="Score"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          onChangeCallback(e.target.value, [markers.fields[0]], `${name}.{index}.score`);
                        }}
                      />
                    )}
                  />

                  <FieldError error={errors?.name?.[index]?.score} />
                </FormGroup>
              </Grid>
            )}

          </Grid>
        ))}
      </Box>
    </Grid>
  </Grid>
);

export default MarkersItems;
