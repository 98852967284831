import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Plus } from 'mdi-material-ui';
import React from 'react';
import { Controller } from 'react-hook-form';
import Button from '../../../../../../components/Button';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import Select from '../../../../../../form/components/Select';
import { content } from '../../../../../Banks/style';
import { headerBtn } from '../../../../../Invoices/style';
import { GreenButton, RedButton } from '../../../../../Logistics/style';
import { PASS_ON_SELECT } from '../../constants';
import { defaultValues } from '../../schema';

export const ExtraItems = ({
  onChangeCallback,
  extraItems,
  form: {
    watch,
    setValue,
    control,
    formState: { errors }
  },
  maxWidth800px = false
}) => (
  <Grid container spacing={1} sx={{ mt: 3, mb: 5 }}>
    <Grid item xs={12}>
      <Typography variant="h5">
        Extra items checked (describe below if any):
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ ...content, ...(maxWidth800px && { minWidth: 'auto' }) }}>
        {extraItems?.fields?.map((item, index) => (
          <Grid
            container
            sx={{
              display: 'flex',
              backgroundColor: 'whitesmoke',
              padding: '5px',
              borderRadius: '8px',
              justifyContent: 'flex-start'
            }}
            key={item.id}
          >
            <Grid item xs={12} lg={12}>
              <Box sx={{ ...(headerBtn), justifyContent: 'space-between' }}>
                <Typography noWrap variant="h6">
                  E
                  {index + 1}
                  {'. '}
                  {watch(`extra_items_checked.${[index]}.item_name`)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={maxWidth800px ? 12 : 4}>
              <FormGroup label="Extra item" hasError={!!errors?.extra_items_checked?.[index]?.item_name}>
                <Controller
                  name={`extra_items_checked.${[index]}.item_name`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Extra item"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors?.extra_items_checked?.[index]?.item_name} />
              </FormGroup>
            </Grid>

            <Grid item xs={maxWidth800px ? 12 : 4}>
              <FormGroup label="Mark for comments" hasError={!!errors?.extra_items_checked?.[index]?.comments}>
                <Controller
                  name={`extra_items_checked.${[index]}.comments`}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={() => {
                        setValue(`extra_items_checked.${[index]}.comments`, !watch(`extra_items_checked.${[index]}.comments`));
                      }}
                    />
                  )}
                />

                <FieldError error={errors?.extra_items_checked?.[index]?.comments} />
              </FormGroup>
            </Grid>

            <Grid item xs={maxWidth800px ? 12 : 4}>
              <FormGroup label="PASSED ON:" hasError={!!errors?.extra_items_checked?.[index]?.attempt}>
                <Controller
                  name={`extra_items_checked.${[index]}.attempt`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={PASS_ON_SELECT}
                      placeholder="PASSED ON:"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        onChangeCallback(e.target.value, extraItems.fields, 'extra_items_checked.{index}.attempt');
                      }}
                    />
                  )}
                />

                <FieldError error={errors?.extra_items_checked?.[index]?.attempt} />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ ...(headerBtn), justifyContent: 'space-between', display: 'flex' }}>
                <Box>
                  <Button
                    title="Add"
                    startIcon={<Plus />}
                    disabled={index >= 4}
                    sx={GreenButton}
                    size="small"
                    onClick={() => extraItems.append({
                      ...defaultValues.extra_items_checked[0],
                    })}
                  />
                </Box>

                <Box>
                  <Button
                    sx={{ ...(RedButton), fontWeight: 600 }}
                    endIcon={<CloseIcon />}
                    size="small"
                    title="Delete"
                    disabled={index === 0}
                    onClick={() => {
                      extraItems.remove(index);
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Grid>
  </Grid>
);
export default ExtraItems;
