import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const PdfViewer = ({ s3Link }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <Box position="relative" width="100%" height="100%">
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={10}
          bgcolor="rgba(255, 255, 255, 0.8)"
        >
          <CircularProgress />
        </Box>
      )}

      {/* PDF iframe */}
      <iframe
        src={s3Link}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        title="PDF Viewer"
        onLoad={handleLoad}
      />
    </Box>
  );
};

export default PdfViewer;
