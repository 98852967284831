import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Upload from '../../../../components/Upload';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import {
  defaultValues, schema
} from './schema';
import {
  fileName
} from './style';

const DocumentForm = ({ onSubmit, maxWidth800px = false }) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const [fileSLB, setFileSLB] = useState();

  const uploadFileSLB = (e) => {
    setFileSLB(e.target.files[0].name);

    setValue('file', e.target.files[0]);
  };

  return (
    <form>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box>
          <Typography variant="h3" textAlign={maxWidth800px ? 'center' : 'start'}>Create New Program</Typography>
        </Box>

        <CardContent sx={{ mt: 5 }}>
          <Grid container spacing={5}>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Program Name" required hasError={!!errors.program_name}>
                <Controller
                  name="program_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Program Name"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.program_name} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Expiry Date" hasError={!!errors.review_date}>
                <Controller
                  name="review_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Enter Expiry Date"
                      dateFormat="yyyy-MM-dd"
                      placeholder="2023-01-01"
                      showMonthDropdown
                      showYearDropdown
                      {...field}
                    />
                  )}
                />
                <FieldError error={errors.review_date} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Threshold Days " hasError={!!errors.threshold_days}>
                <Controller
                  name="threshold_days"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Threshold Days"
                      {...field}
                    />
                  )}
                />

                <FieldError error={errors.threshold_days} />
              </FormGroup>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormGroup label="Sillaby Review File">
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFileSLB(e);
                      }}
                      title="Upload File"
                    />
                  )}
                />
                <FieldError error={errors.file} />
              </FormGroup>
              <Typography sx={fileName}>{fileSLB}</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormGroup label="Remarks">
                <Controller
                  name="feedback"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Remarks"
                      multiline
                      rows={4}
                      fullWidth
                      {...field}
                    />
                  )}
                />

              </FormGroup>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default DocumentForm;
