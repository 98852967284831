import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import LinkButton from '../../../components/LinkButton/Button';
import { CAPTAIN, CO_PILOTS, CREW_POST_OPTIONS } from '../../../constans/crew';
import CustomAutocomplete from '../../../form/components/Autocomplete';
import Checkbox from '../../../form/components/Checkbox/Checkbox';
import DatePicker from '../../../form/components/DatePicker';
import FieldError from '../../../form/components/FieldError';
import FormGroup from '../../../form/components/FormGroup';
import Input from '../../../form/components/Input';
import Select from '../../../form/components/Select';
import { useGetPositionsQuery } from '../../../store/session';
import { getOptions } from '../../../utils/getOptions';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { validateDatePickerValue } from '../../../utils/setDateValue';
import {
  Container,
  ContainerSmall,
  ContainerSx, formControlLabel, linkButtonSx
} from './styles';
import { useGeneral } from './useGeneral';

const General = ({
  post,
  userList,
  setValue,
  data
}) => {
  const { errors, control } = useGeneral();
  const params = useParams();

  const maxWidth590px = useMediaQuery('(max-width:590px)');
  const maxWidth768px = useMediaQuery('(max-width:768px)');
  const userAccess = usePermissions('users_access');

  const { data: positionList, isLoading: positionLoading } = useGetPositionsQuery();
  const positionsListOptions = getOptions(positionList);

  const handleChangePositions = (posData, onChange) => {
    onChange(posData);
    setValue('positions', posData, { shouldTouch: true });
  };

  return (
    <Grid
      container
      spacing={2}
      rowSpacing={2}
      sx={{ ...Container, ...(maxWidth768px && ContainerSx), ...(maxWidth590px && ContainerSmall) }}
    >
      {[CAPTAIN, CO_PILOTS].includes(+post) && (
      <Grid item sx={12} lg={12}>
        <LinkButton path={`/crew/ftl/${params.id}`} style={linkButtonSx}>
          FLIGHT TIME LIMITATION TABLE
        </LinkButton>
      </Grid>
      )}

      <Grid item xs={12}>
        <FormGroup>
          <Controller
            name="crew_active"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                sx={formControlLabel}
                control={<Checkbox />}
                label="ROSTER ACTIVE"
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={maxWidth768px ? 12 : 3}>
        <FormGroup>
          <Controller
            name="crew_user_id"
            control={control}
            render={({ field }) => (
              <Autocomplete
                freeSolo
                value={userList?.filter((UserItem) => UserItem?.id === field?.value)[0] || null}
                options={userList}
                getOptionLabel={(option) => `${option.username} - ${option.name}` || ''}
                isOptionEqualToValue={(option, UserListItem) => option?.id === UserListItem?.id || null}
                renderInput={(params_) => (
                  <TextField
                    {...params_}
                    size="small"
                    inputRef={field.ref}
                    label="User Account"
                  />
                )}
                onChange={(e, value) => {
                  field.onChange(e);
                  setValue('crew_user_id', value?.id);
                }}
              />
            )}
          />

          <FieldError error={errors.crew_user_id} />
        </FormGroup>
      </Grid>

      {(data?.crew_user_id) && (
        <Grid item xs={maxWidth768px ? 12 : 4}>
          <FormGroup>
            <Controller
              name="positions"
              control={control}
              render={({ field }) => (
                <CustomAutocomplete
                  {...field}
                  multiple
                  id="tags-outlined"
                  placeholder="Positions"
                  options={positionsListOptions}
                  disabled={positionLoading}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, item) => option.value === item.value}
                  onChange={(e, posData) => {
                    field.onChange(e);
                    handleChangePositions(posData, field.onChange);
                  }}
                  value={field.value ?? []}
                />
              )}
            />
            <FieldError />
          </FormGroup>
        </Grid>
      )}

      {(data?.crew_user_id && userAccess) && (
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <LinkButton target="_blank" path={`/manage-users/${data.crew_user_id}`}>
            <Button title="Open Portal Profile"></Button>
          </LinkButton>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormGroup label="Name" required hasError={!!errors.crew_name}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Name"
                {...field}
              />
            )}
            control={control}
            name="crew_name"
          />

          <FieldError error={errors.crew_name} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Shortname" hasError={!!errors.crew_shortname}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Shortname"
                {...field}
              />
            )}
            control={control}
            name="crew_shortname"
          />

          <FieldError error={errors.crew_shortname} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Post" required hasError={!!errors.crew_post}>
          <Controller
            render={({ field }) => (
              <Select
                options={CREW_POST_OPTIONS}
                placeholder="Post"
                {...field}
              />
            )}
            control={control}
            name="crew_post"
          />

          <FieldError error={errors.crew_post} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Birthday" hasError={!!errors.crew_birth}>
          <Controller
            render={({ field: { onChange, ...req } }) => (
              <DatePicker
                {...req}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                onChange={(e) => {
                  onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                }}
                value={validateDatePickerValue(req.value)}
              />
            )}
            control={control}
            name="crew_birth"
          />

          <FieldError error={errors.crew_birth} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Passport" hasError={!!errors.crew_pasport}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Passport"
                {...field}
              />
            )}
            control={control}
            name="crew_pasport"
          />

          <FieldError error={errors.crew_pasport} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Expires" hasError={!!errors.crew_expires}>
          <Controller
            render={({ field: { onChange, ...req } }) => (
              <DatePicker
                {...req}
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                onChange={(e) => {
                  onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                }}
                value={validateDatePickerValue(req.value)}
              />
            )}
            control={control}
            name="crew_expires"
          />

          <FieldError error={errors.crew_expires} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Nationality" hasError={!!errors.crew_nationality}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Nationality"
                {...field}
              />
            )}
            control={control}
            name="crew_nationality"
          />

          <FieldError error={errors.crew_nationality} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Home base" hasError={!!errors.crew_home_base}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Home base"
                {...field}
              />
            )}
            control={control}
            name="crew_home_base"
          />

          <FieldError error={errors.crew_home_base} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Email" hasError={!!errors.crew_email}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Email"
                {...field}
              />
            )}
            control={control}
            name="crew_email"
          />

          <FieldError error={errors.crew_email} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Licence" hasError={!!errors.crew_licence}>
          <Controller
            render={({ field } ) => (
              <Input
                placeholder="Licence"
                {...field}
              />
            )}
            control={control}
            name="crew_licence"
          />

          <FieldError error={errors.crew_licence} />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Proficiency notes" hasError={!!errors.crew_proficiency_notes}>
          <Controller
            render={({ field } ) => (
              <Input
                multiline
                rows={4}
                placeholder="Proficiency notes"
                {...field}
              />
            )}
            control={control}
            name="crew_proficiency_notes"
          />

          <FieldError error={errors.crew_proficiency_notes} />
        </FormGroup>
      </Grid>
    </Grid>
  ); };

export default General;
