import React from 'react';
import { useInvoiceManagement } from './useInvoiceManagement';
import {
  Box,
  Card,
  Typography,
} from '@mui/material';
import {
  BoxHeader,
  BoxHeaderFirst,
  CardHeaderSx, MainHeader
} from './styles';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import Dashboard from './Dashboard/Dashboard';

const InvoiceManagement = () => {
  const invoiceProps = useInvoiceManagement();

  return (
    <Box>
      {/* Page Header */}
      <Card sx={CardHeaderSx}>
        <Box sx={BoxHeader}>
          <Box sx={BoxHeaderFirst}>
            <Typography noWrap variant="h4" sx={MainHeader}>
              <DashboardCustomizeIcon />
              Invoice Management
            </Typography>
          </Box>
        </Box>
      </Card>

      {/* Invoice Dashboard */}
      <Dashboard props={invoiceProps} />
    </Box>
  );
};

export default InvoiceManagement;
