import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AirlinesIcon from '@mui/icons-material/Airlines';
import BuildIcon from "@mui/icons-material/Build";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Chip from "@mui/material/Chip";

/**
 * Invoice Status: Pending approve invoice
 *
 * @type {number}
 */
export const PENDING_APPROVE_OCCM = 0;

/**
 * Invoice Status: Pending approve expensive invoice ( >= 50k eur)
 *
 * @type {number}
 */
export const PENDING_APPROVE_AM = 1;

/**
 * Invoice Status: Pending approve logistic invoice
 *
 * @type {number}
 */
export const PENDING_APPROVE_LOGIST = 6;

/**
 * Invoice Status: After approval
 *
 * @type {number}
 */
export const PENDING_PAYMENT = 2;

/**
 * Invoice Status: Payed
 *
 * @type {number}
 */
export const PAYED = 3;

/**
 * Invoice Status: Not Payed
 *
 * @type {number}
 */
export const NOT_PAYED = 4;

/**
 * Invoice Status: DECLINED
 *
 * @type {number}
 */
export const DECLINED = 5;

/**
 * Invoice Status: Prepayment
 *
 * @type {number}
 */
export const PREPAYMENT = 7;

/**
 * Invoice Status: Issue
 *
 * @type {number}
 */
export const ISSUE = 8;

export const PENDING_APPROVAL_STATUSES = [
  PENDING_APPROVE_OCCM,
  PENDING_APPROVE_AM,
  PENDING_APPROVE_LOGIST
];

export const PENDING_PAYMENT_STATUSES = [
  PENDING_PAYMENT
];

export const PREPAYMENT_STATUSES = [
  PREPAYMENT
];

export const ISSUE_STATUSES = [
  ISSUE
];

export const PAYED_STATUSES = [
  PAYED,
  NOT_PAYED
];

export const DASHBOARD_CONFIG = [
  { title: 'Pending Approval', statuses: PENDING_APPROVAL_STATUSES, bgColor: '#ffff001f' },
  { title: 'Pending Payment', statuses: PENDING_PAYMENT_STATUSES, bgColor: '#0000ff0a' },
  { title: 'Prepayment', statuses: PREPAYMENT_STATUSES, bgColor: '#00ffff12' },
  { title: 'Issue', statuses: ISSUE_STATUSES, bgColor: 'rgba(0,119,255,0.12)' },
  { title: 'Payed', statuses: PAYED_STATUSES, bgColor: '#69c68b1a' }
];

export const INVOICE_TYPES = [
  // eslint-disable-next-line react/jsx-filename-extension
  { value: 'request', label: 'Regular', icon: (<ReceiptLongIcon />) },
  { value: 'delivery', label: 'Delivery', icon: (<LocalShippingIcon />) },
  { value: 'part', label: 'Part', icon: (<AirlinesIcon />) },
  { value: 'overhaul', label: 'Overhaul', icon: (<BuildIcon />) },
  { value: 'exchange', label: 'Exchange', icon: (<SyncAltIcon />) },
];

export const INVOICE_STATUSES = [
  // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
  { label: '', value: '0', icon: (<Chip label="PENDING APPROVE OCCM" size="small" color="secondary" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '1', icon: (<Chip label="PENDING APPROVE AM" size="small" color="warning" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '2', icon: (<Chip label="PENDING PAYMENT" size="small" color="info" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '3', icon: (<Chip label="PAYED" size="small" color="success" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '4', icon: (<Chip label="NOT PAYED" size="small" color="error" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '5', icon: (<Chip label="DECLINED" size="small" color="error" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '6', icon: (<Chip label="PENDING APPROVE LOGIST" size="small" color="primary" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '7', icon: (<Chip label="PREPAYMENT" size="small" color="primary" sx={{ fontWeight: 'bolder' }} />) },
  { label: '', value: '8', icon: (<Chip label="ISSUE" size="small" color="primary" sx={{ fontWeight: 'bolder' }} />) },
];

export const INVOICE_STATUS_OPTIONS = [
  { value: '0', label: 'PENDING APPROVE OCCM' },
  { value: '1', label: 'PENDING APPROVE AM' },
  { value: '2', label: 'PENDING PAYMENT' },
  { value: '3', label: 'PAYED' },
  { value: '4', label: 'NOT PAYED' },
  { value: '5', label: 'DECLINED' },
  { value: '6', label: 'PENDING APPROVE LOGIST' },
];

export const INVOICE_TYPE_OPTIONS = [
  { value: 'request', label: 'Request' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'part', label: 'Part' },
  { value: 'overhaul', label: 'Overhaul' },
  { value: 'exchange', label: 'Exchange' },
];
