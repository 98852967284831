import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Card, Grid, InputAdornment, TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import FileSaver from 'file-saver';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import {
  useDeleteOdsActionsMutation,
  useGetAircraftListQuery,
  useGetOdsActionsQuery,
  useGetOdsAtaQuery,
  useGetPortalFilesMutation,
  useOdsActionsExportToExcelMutation,
  useOdsActionsMutation,
  useUploadPortalFilesMutation,
} from '../../../store/session';
import { OdsActionDocumentModel, setFilesName } from '../../../utils/constants/portalFiles';
import { getOptions } from '../../../utils/getOptions';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { GreenButton } from '../../Logistics/style';
import FileDrawer from '../../TrainingModule/FileDrawer/FileDrawer';
import { columns } from './columns';
import { TitleContainer } from './styles';

const Crew = () => {
  const { data: AircraftList } = useGetAircraftListQuery();
  const { data: odsAtaList } = useGetOdsAtaQuery();
  const [search, setSearch] = useState('');
  const [tableSort, setTableSort] = useState(null);
  const [tableFilter, setTableFilter] = useState(null);
  const [pageOptions, setPageOptions] = useState(null);
  const [getFiles, { isLoading: isLoadingCrewFiles }] = useGetPortalFilesMutation();
  const [uploadFiles, { isLoading: isAttachLoading }] = useUploadPortalFilesMutation();
  const [getExcel, { isLoading: isExportDataLoading }] = useOdsActionsExportToExcelMutation();
  const filesPermission = usePermissions('ods_action_upload_file');
  const maxWidth768px = useMediaQuery('(max-width:768px)');
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const aircraftOptions = AircraftList?.map((item) => ({
    value: item.id,
    label: item.aircraft_registration,
  }));

  const odsAtaOptions = getOptions(odsAtaList);

  const submitExportToExcel = async () => {
    let excelData = {};
    
    if (tableSort) {
      excelData = {
        ...excelData,
        sortDir: tableSort?.sort?.toUpperCase(),
        sortField: tableSort?.field
      };
    } else {
      excelData = { ...excelData, ...{ sortDir: 'DESC', sortField: 'performed' } };
    }

    if (tableFilter) {
      excelData = { ...excelData, ...tableFilter };
    }

    if (pageOptions) {
      excelData = { ...excelData, ...pageOptions };
    } else {
      excelData = { ...excelData, ...{ page: 1, pageSize: 15 } };
    }
    const res = await getExcel(stringifyLocationSearch(excelData));

    FileSaver.saveAs(res?.data, 'Actions performed');
  };

  const getFileList = async (id, callback) => {
    const res = await getFiles({
      model_id: id,
      model: OdsActionDocumentModel.name,
    });

    if (res?.error) {
      toast.error('Unable to get file list.');

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const updateFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('model_id', id);
    formData.append('model', OdsActionDocumentModel.name);

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadFiles(formData);

    if (res?.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  useEffect(() => {
    if (odsAtaOptions) {
      odsAtaOptions?.push({ value: '', label: '-' });
    }
  }, [odsAtaOptions]);

  return (
    <Card sx={{ padding: '1rem' }}>
      <Grid sx={{ display: 'flex', flexDirection: 'column', mb: 5 }}>
        <Box sx={{ ...TitleContainer, pb: 3 }}>
          <Typography variant="h3">Actions Performed</Typography>
        </Box>
        <Box sx={maxWidth768px ? {
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        } : {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <TextField
              size="small"
              autoComplete="off"
              type="text"
              sx={maxWidth768px ? {
                width: '100%'
              } : {
                minWidth: '300px', maxWidth: '300px'
              }}
              placeholder="Search this board"
              onChange={debounce((e) => setSearch(e.target.value), 400)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Button
              disabled={isExportDataLoading}
              sx={{ 
                ...GreenButton,
                ml: 4,
                ...(maxWidth768px && { width: '100%', ml: 0 })
              }}
              onClick={submitExportToExcel}
              title="Export to Excel"
            />
          </Box>
        </Box>
      </Grid>

      <EditableTable
        useHook={useGetOdsActionsQuery}
        getRowId={(row) => row.action_id}
        useHookUpdate={useOdsActionsMutation}
        useHookDelete={useDeleteOdsActionsMutation}
        columns={columns}
        sortByType="DESC"
        sortByActive="performed"
        aircraftOptions={aircraftOptions}
        odsAtaOptions={odsAtaOptions}
        getRowHeight={() => 'auto'}
        handleFileDrawer={handleFileDrawer}
        setSortOut={setTableSort}
        setFilterOut={setTableFilter}
        setPageOptions={setPageOptions}
        dateProps={{
          performed: {
            showTimeSelect: true,
            timeIntervals: 1,
            placeholderText: 'YYYY-MM-DD H:m',
            dateFormat: 'yyyy-MM-dd HH:mm',
          },
        }}
        tableParams={{
          search
        }}
      />

      <FileDrawer 
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name} 
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList} 
        uploadFile={(file) => updateFiles(fileDrawerState.currentId, file, fileDrawerState.type, () => handleFileDrawer(true, fileDrawerState.currentId, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
        isAttachLoading={isAttachLoading}
        hasPermissions={filesPermission}
        isLoadingFiles={isLoadingCrewFiles}
      />
    </Card>
  );
};

export default Crew;
