import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete as AutocompleteMui, Box, Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import Autocomplete from '../../../../form/components/Autocomplete';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import { useGenerateNotificationMutation, useGetInvoicesListQuery } from '../../../../store/slices/financeSlice';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { StyledCard } from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/InvoiceTable';
import { HeaderTypography } from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/InvoiceTableHeader';
import { tableHeaderContent } from '../constants';
import { defaultValues, schema } from './schema';

const GenerateNotification = ({
  open, onClose, flightId, brokerEmail = '', refetchTable, tableData, handleOpenFile, invoiceId, isLoadingOpenInvoice = false
}) => {
  const { data: invoicesList } = useGetInvoicesListQuery({ id: flightId }, {
    refetchOnMountOrArgChange: true,
    skip: !flightId
  });
  const [generateNotification] = useGenerateNotificationMutation();
  const handleError = useHandleQueryFormError();

  const {
    handleSubmit,
    setValue,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    const invoiceList = data.invoices.map(item => +item.value);
    const res = await generateNotification({ data: { emails: data.emails, invoices: invoiceList } });

    if (res.error) {
      if (res.error.status === 403) {
        toast.error('Access is denied', {
          id: toastId,
        });

        return;
      }
      handleError(res.error, setError, getValues, toastId);

      return;
    }

    refetchTable();
    
    toast.success('The Notification was generate!', {
      id: toastId,
    });

    onClose();
    reset();
  };

  const invoicesOptions = Object.entries(invoicesList || {}).map(([key, value]) => ({
    label: value,
    value: key
  }));

  useEffect(() => {
    const changeBrokerEmail = brokerEmail?.split(', ');

    setValue('emails', changeBrokerEmail);
  }, [open]);

  return (
    <Drawer
      title="Generate Notification"
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      sx={{
        '& > .MuiDrawer-paperAnchorRight': {
          // height: '100vh',
          overflowY: 'auto',
          // display: 'grid'
        },
        '.MuiTypography-root': {
          margin: '0 auto'
        }
      }}
    >
      <Card sx={{
        mt: 10,
        padding: 5,
        overflow: 'visible'
      }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <FormGroup required label="Emails" hasError={!!errors.emails}>
              <Controller
                name="emails"
                control={control}
                render={({ field }) => (
                  <AutocompleteMui
                    {...field}
                    multiple
                    id="tags-filled"
                    options={[]}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label)}
                    onChange={(e, value) => {
                      field.onChange(value);
                      setValue('emails', value);
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter email addresses"
                      />
                    )}
                  />
                )}
              />
              <FieldError error={errors.emails} />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup required label="Invoices" hasError={!!errors.invoices}>
              <Controller
                name="invoices"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={invoicesOptions || []}
                    multiple
                    size="small"
                    placeholder="Please select a Invoices"
                    onChange={(e, value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
              <FieldError error={errors.invoices} />
            </FormGroup>
          </Box>
          <Button type="submit" sx={{ mt: 5 }}>Send</Button>
        </form>
      </Card>
      {tableData && (
      <Card sx={{
        padding: 5,
        overflowY: 'auto'
      }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        >
          <Box>
            <Typography variant="h5">Notification History</Typography>
          </Box>
          <Button 
            title="Open Invoice File" 
            onClick={() => handleOpenFile(invoiceId)}
            disabled={isLoadingOpenInvoice}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <CardContent sx={{
            margin: '0',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px 0px',
          }}
          >
            <Grid container alignItems="center" spacing={2}>
              {
                tableHeaderContent.map(({ title, xs }) => (
                  <Grid item xs={xs}>
                    <HeaderTypography variant="body1">{title}</HeaderTypography>
                  </Grid>
                ))
              }
            </Grid>
          </CardContent>
          <Box>
            {tableData.map(notifi => (
              <StyledCard sx={{ width: 'auto!important' }}>
                <CardContent sx={{
                  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px 0px',
                }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    { Object.values({
                      sender: notifi?.sender,
                      emails: notifi?.emails,
                      invoices: notifi?.invoices,
                      date: notifi?.date
                    }).map((value) => {
                      if (Array.isArray(value)) {
                        return (
                          <Grid item xs={3} justifySelf="start" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            {value.map(vlv => (
                              <Typography
                                sx={{
                                  whiteSpace: 'break-spaces',
                                  wordBreak: 'break-all',
                                  margin: '0!important'
                                }}
                              >
                                {vlv}
                              </Typography>
                            ))}
                          </Grid>
                        );
                      }

                      return (
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              whiteSpace: 'break-spaces',
                              wordBreak: 'break-all',
                              margin: '0!important'
                            }}
                          >
                            {value}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
              </StyledCard>
            ))}
          </Box>
          
        </Box>
      </Card>
      )}
    </Drawer>
  );
};

export default GenerateNotification;