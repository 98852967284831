import {
  Box, Card,
  CircularProgress, Divider,
  Grid,
  useMediaQuery
} from '@mui/material'
import React, { useEffect } from 'react'
import {
  Controller, useFormContext
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { DATE_FORMAT } from '../../../../../../constans/formats'
import { PART_TYPES } from '../../../../../../constans/logistics'
import DatePicker from '../../../../../../form/components/DatePicker'
import FormGroup from '../../../../../../form/components/FormGroup'
import Input from '../../../../../../form/components/Input'
import Select from '../../../../../../form/components/Select'
import { useGetUnitsListQuery } from '../../../../../../store/slices/logisticsSlice'
import { getOptions } from '../../../../../../utils/getOptions'
import { usePermissions } from '../../../../../../utils/hooks/usePermissions'
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue'
import Certificates from '../../../../OrdersDashboard/components/forms/onTheWay/components/certificates/Certificates'
import { PartsStorage } from '../../../constants'

const PartTab = ({
  data,
  isLoading,
  create,
  aircraftOptions
}) => {
  const params = useParams();
  const { data: units, isLoading: isUnitsLoading } = useGetUnitsListQuery();
  const unitsOptions = getOptions(units);
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  // Access
  const hasPermissions = usePermissions(['logistics_storage_write', 'logistics_storage_master', 'internal_logistics_storage_master']);
  const hasInternalPermissions = usePermissions('internal_logistics_storage_master');

  const {
    watch,
    control,
    formState: { errors },
    setValue
  } = useFormContext();

  const partType = watch('part_type');
  const isConsumable = partType === 'consumable';
  const isInstrument = partType === 'instrument';
  const isCreateInstrument = params.id.includes('instrument');

  const optionsAircraft = aircraftOptions.map(item => ({
    value: item?.label.toLowerCase(), label: item?.label
  }));

  const optionsSelectStorage = [
    { value: 'hof', label: 'Hof' },
    { value: 'budapest', label: 'Budapest' },
    ...optionsAircraft
  ];

  useEffect(() => {
    if (isCreateInstrument) {
      setValue('part_type', 'instrument');
    }
  }, [isCreateInstrument]);

  return (
    <form>
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          spacing={3}
          rowSpacing={3}
        >
          <Grid item xs={(!create) ? maxWidth800px ? 12 : 4.9 : 12}>
            <Card>
              <Grid item xs={12}>
                <FormGroup label="Storage" required hasError={!!errors.storage}>
                  <Controller
                    name="storage"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={optionsSelectStorage}
                        placeholder="Storage"
                        disabled={(!hasPermissions && !hasInternalPermissions)}
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Storage Number" required hasError={!!errors.storage_number}>
                  <Controller
                    name="storage_number"
                    control={control}
                    render={({ field }) => (
                      <Input placeholder="Storage Number" {...field} disabled={(!hasPermissions && !hasInternalPermissions)} />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Storage Zone" hasError={!!errors.storage_zone}>
                  <Controller
                    name="storage_zone"
                    control={control}
                    render={({ field }) => (
                      <Input placeholder="Storage Zone" {...field} disabled={(!hasPermissions && !hasInternalPermissions)} />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Part number" required hasError={!!errors.part_number}>
                  <Controller
                    name="part_number"
                    control={control}
                    render={({ field }) => (
                      <Input placeholder="Part number" {...field} disabled={(!hasPermissions && !hasInternalPermissions)} />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Part name" required hasError={!!errors.part_name}>
                  <Controller
                    name="part_name"
                    control={control}
                    render={({ field }) => (
                      <Input placeholder="Part name" {...field} disabled={(!hasPermissions && !hasInternalPermissions)} />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Serial number" hasError={!!errors.serial_number}>
                  <Controller
                    name="serial_number"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Serial number"
                        {...field}
                        disabled={(!hasPermissions && !hasInternalPermissions)}
                        onChange={(e) => {
                          if (e.target.value !== '') {
                            setValue('quantity', 1);
                          }

                          field.onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              <Grid content spacing={2}>
                <Grid item xs={12}>
                  <FormGroup label="Part type" required hasError={!!errors.part_type}>
                    <Controller
                      name="part_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={PART_TYPES}
                          placeholder="Part Type"
                          disabled={(!hasPermissions && !hasInternalPermissions)}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={6} sx={{ display: isConsumable ? 'block' : 'none' }}>
                  <FormGroup label="Unit" required hasError={!!errors.unit_id}>
                    <Controller
                      name="unit_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={unitsOptions}
                          placeholder="Units"
                          disabled={(!hasPermissions && !hasInternalPermissions) || isUnitsLoading}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Exchange Due Date">
                  <Controller
                    render={({ field } ) => (
                      <DatePicker
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'exchange_due_date', DATE_FORMAT);
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                    control={control}
                    name="exchange_due_date"
                  />
                </FormGroup>
              </Grid>

              <Grid content spacing={2} sx={{ display: isInstrument ? 'block' : 'none' }}>
                <Grid item xs={12}>
                  <FormGroup label="Instrument ID" hasError={!!errors.instrument_id}>
                    <Controller
                      name="instrument_id"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Instrument ID" {...field} disabled={(!hasPermissions && !hasInternalPermissions)} />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup label="Due Date">
                    <Controller
                      render={({ field } ) => (
                        <DatePicker
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          onChange={(e) => {
                            field.onChange(e);
                            setDateValue(e, setValue, 'due_date', DATE_FORMAT);
                          }}
                          value={validateDatePickerValue(field.value)}
                        />
                      )}
                      control={control}
                      name="due_date"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid content spacing={2}>
                <Grid item xs={12}>
                  <FormGroup label="Description" hasError={!!errors.description}>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Description" {...field} rows={6} multiline disabled={(!hasPermissions && !hasInternalPermissions)} />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup label="Quantity" required hasError={!!errors.quantity}>
                    <Controller
                      name="quantity"
                      type="number"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="number"
                          placeholder="Quantity"
                          {...field}
                          disabled={
                            (!hasPermissions && !hasInternalPermissions)
                            || ((watch('serial_number') !== '')
                            && (watch('serial_number') !== 'N/A'))
                          }
                          onWheel={(e) => e.target.blur()}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup label="Tracking Number" hasError={!!errors.ttn_code}>
                    <Controller
                      name="ttn_code"
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Tracking Number" {...field} disabled={(!hasPermissions && !hasInternalPermissions)} />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormGroup label="Part State" required hasError={!!errors.part_state}>
                  <Controller
                    name="part_state"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={PartsStorage}
                        placeholder="Part State"
                        {...field}
                        disabled={(!hasPermissions && !hasInternalPermissions)}
                        onChange={e => {
                          if ((e.target.value === 9) || (e.target.value === 10)) return;
                          field.onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

            </Card>
          </Grid>

          {(!create) && (
          <>
            <Grid item xs={0.1}>
              <Divider orientation={maxWidth800px ? 'horizontal' : "vertical"} />
            </Grid>
            <Grid item xs={maxWidth800px ? 12 : 6.9}>
              <Certificates order={data} part permission={hasPermissions} />
            </Grid>
          </>
          )}
        </Grid>
      )}
    </form>
  );
};

export default PartTab;
