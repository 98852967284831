import { Grid, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Controller } from 'react-hook-form';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Select from '../../../../../../form/components/Select';
import { content } from '../../../../../Banks/style';
import { headerBtn } from '../../../../../Invoices/style';
import { PASS_ON_SELECT } from '../../constants';

export const ProceduresItems = ({
  abnormalAndEmergencyProcedures,
  onChangeCallback,
  form: {
    watch,
    setValue,
    control,
    formState: { errors }
  },
  maxWidth800px = false
}) => (
  <Grid container spacing={1} sx={{ mt: 3, mb: 5 }}>
    <Grid item xs={12}>
      <Typography variant="h5">
        ABNORMAL AND EMERGENCY PROCEDURES:
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ ...content, ...(maxWidth800px && { minWidth: 'auto' }) }}>
        {abnormalAndEmergencyProcedures?.fields?.map((item, index) => (
          <Grid
            container
            sx={{
              display: 'flex',
              backgroundColor: 'whitesmoke',
              padding: '5px',
              borderRadius: '8px',
              justifyContent: 'flex-start'
            }}
            key={item.id}
          >
            <Grid item xs={12} lg={12}>
              <Box sx={{ ...(headerBtn), justifyContent: 'space-between', ...(maxWidth800px && { display: 'flex' }) }}>
                <Typography noWrap variant="h6">
                  {index + 9}
                  {'. '}
                  {item.item_name}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={maxWidth800px ? 12 : 6}>
              <FormGroup label="Mark for comments" hasError={!!errors?.abnormal_and_emergency_procedures?.[index]?.comments}>
                <Controller
                  name={`abnormal_and_emergency_procedures.${[index]}.comments`}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={() => {
                        setValue(`abnormal_and_emergency_procedures.${[index]}.comments`, !watch(`abnormal_and_emergency_procedures.${[index]}.comments`));
                      }}
                    />
                  )}
                />

                <FieldError error={errors?.abnormal_and_emergency_procedures?.[index]?.comments} />
              </FormGroup>
            </Grid>

            <Grid item xs={maxWidth800px ? 12 : 6}>
              <FormGroup label="PASSED ON:" hasError={!!errors?.abnormal_and_emergency_procedures?.[index]?.attempt}>
                <Controller
                  name={`abnormal_and_emergency_procedures.${[index]}.attempt`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={PASS_ON_SELECT}
                      placeholder="PASSED ON:"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        onChangeCallback(e.target.value, abnormalAndEmergencyProcedures.fields, 'abnormal_and_emergency_procedures.{index}.attempt');
                      }}
                    />
                  )}
                />

                <FieldError error={errors?.abnormal_and_emergency_procedures?.[index]?.attempt} />
              </FormGroup>
            </Grid>

          </Grid>
        ))}
      </Box>
    </Grid>
  </Grid>
);

export default ProceduresItems;
