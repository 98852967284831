import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card, CardContent,
  CircularProgress,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useCallback } from 'react';
import { useGetInvoiceDetailsQuery } from '../../../../../store/slices/invoiceManagementSlice';
import { headerBtn } from '../../../../Invoices/style';
import { TextWithIcon, TextWrap } from '../../../../Logistics/style';
import {
  CloseIconStyle, DetailsGridStyle, OrderDetailsStyle, RightMenuStyle
} from '../../../../Logistics/OrdersDashboard/styles';
import { CardAction } from '../../styles';
import DownloadIcon from '@mui/icons-material/Download';
import {
  INVOICE_STATUSES, PAYED,
  PENDING_APPROVE_AM,
  PENDING_APPROVE_LOGIST,
  PENDING_APPROVE_OCCM,
  PENDING_PAYMENT, PREPAYMENT
} from '../../constants';
import PendingPaymentForm from './forms/PendingPaymentForm';
import PendingApproveForm from './forms/PendingApproveForm';
import PayedForm from './forms/PayedForm';
import PrepaymentForm from './forms/PrepaymentForm';

const InvoiceForm = ({
  onClose, locationInvoiceId = null, props
}) => {
  const { data: invoice, isLoading: invoiceLoading } = useGetInvoiceDetailsQuery(
    { id: locationInvoiceId },
    {
      refetchOnMountOrArgChange: true,
      skip: !locationInvoiceId
    }
  );

  const formattedInvoice = props.formatInvoice(invoice);
  const handleInvoiceTitle = useCallback((with_id = true) => props.getInvoiceTitle(invoice, with_id), [invoice]);

  const renderSidebarMainInfo = useCallback((invoiceData) => ([
    { label: 'Status', value: INVOICE_STATUSES.find(i => i.value === invoiceData?.payment_status?.toString())?.icon },
    { label: 'Purpose', value: invoiceData?.payment_purpose },
    { label: 'Sender', value: invoiceData?.sender },
    { label: 'Due date', value: invoiceData?.due_payment_date },
    { label: 'Payment date', value: invoiceData?.payment_date },
    { label: 'Created', value: invoiceData?.created_at },
  ].map((item, index) => item.value && (
    <Grid key={index} item xs={12}>
      <Box
        sx={{
          display: 'column', justifyContent: 'left'
        }}
      >
        <Typography noWrap variant="subtitle2">
          {item.label}
        </Typography>
        <Typography noWrap variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
          <b>{typeof item.value === 'function' ? item.value() : item.value}</b>
        </Typography>
      </Box>
    </Grid>
  ))
  ), [invoice]);

  const renderSidebarFileInfo = useCallback((invoiceData) => ([
    { label: `Download ${invoiceData?.is_proforma ? 'Proforma' : 'Invoice'}`, value: invoiceData?.invoice_path },
    { label: 'Download Proof of payment', value: invoiceData?.proof_of_payment_file_path },
  ].map((item, index) => item.value && (
    <Grid key={index} container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={CardAction}
          onClick={() => props.handleDownloadInvoice({ id: invoice.id, file_name: item.value })}
        >
          <DownloadIcon />
          {item.label}
        </Box>
      </Grid>
    </Grid>
  ))
  ), [invoice]);
  const handleFormSubmit = () => {
    onClose();
    props.refetchBoard();
  };

  const renderInvoiceForm = useCallback(() => {
    switch (invoice?.payment_status) {
      case PENDING_APPROVE_OCCM:
      case PENDING_APPROVE_AM:
      case PENDING_APPROVE_LOGIST:
        return <PendingApproveForm invoiceId={invoice?.id} s3Link={invoice?.s3_link} onSubmit={handleFormSubmit} />;
      case PENDING_PAYMENT:
        return <PendingPaymentForm invoiceId={invoice?.id} onSubmit={handleFormSubmit} />;
      case PAYED:
        return <PayedForm invoiceId={invoice?.id} onSubmit={handleFormSubmit} />;
      case PREPAYMENT:
        return <PrepaymentForm />;
      default:
        return null;
    }
  }, [invoice]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={!!locationInvoiceId}
      onClose={onClose}
    >
      {invoiceLoading ? (
        <DialogContent sx={{ alignSelf: 'center' }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={9.5} sx={{ backgroundColor: '#e9f1fe26' }}>
            <DialogContent>
              <Card sx={{ padding: '1rem' }}>
                <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h5" noWrap>
                      {handleInvoiceTitle()}
                    </Typography>
                  </Box>

                  <Box sx={TextWithIcon}>
                    {formattedInvoice?.type?.icon}
                    {formattedInvoice?.type?.label}
                  </Box>
                </Box>

                <Divider />

                <Grid container spacing={1}>
                  {invoice?.amount && (
                    <Grid item xs={12}>
                      <Typography sx={TextWithIcon}>
                        <b>Amount:</b>
                        <Typography sx={TextWrap}>{invoice.amount}</Typography>
                      </Typography>
                    </Grid>
                  )}
                  {invoice?.description && (
                    <Grid item xs={12}>
                      <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                        <b>Description:</b>
                        <Typography sx={TextWrap}>{invoice.description}</Typography>
                      </Typography>
                    </Grid>
                  )}
                  {invoice?.decline_purpose && (
                    <Grid item xs={12}>
                      <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                        <b>Issue Reason:</b>
                        <Typography sx={TextWrap}>{invoice?.decline_purpose}</Typography>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Divider />
                {invoice?.proforma && (
                  <>
                    {invoice?.proforma?.amount && (
                      <Grid item xs={12}>
                        <Typography sx={TextWithIcon}>
                          <b>Proforma Amount: </b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.amount}</Typography>
                        </Typography>
                      </Grid>
                    )}
                    {invoice?.proforma?.description && (
                      <Grid item xs={12}>
                        <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                          <b>Proforma Description:</b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.description}</Typography>
                        </Typography>
                      </Grid>
                    )}
                    {invoice?.proforma?.decline_purpose && (
                      <Grid item xs={12}>
                        <Typography sx={{ ...TextWithIcon, alignItems: 'start' }}>
                          <b>Issue Reason:</b>
                          <Typography sx={TextWrap}>{invoice?.proforma?.decline_purpose}</Typography>
                        </Typography>
                      </Grid>
                    )}

                    <Divider />
                  </>
                )}
              </Card>

              <Grid item xs={12}>
                {renderInvoiceForm()}
              </Grid>
            </DialogContent>
          </Grid>

          <Grid item xs={2.5} sx={DetailsGridStyle}>
            <Grid container>
              <Grid item xs={12} sx={RightMenuStyle}>
                <CloseIcon
                  sx={CloseIconStyle}
                  onClick={onClose}
                />
              </Grid>
              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    {invoice?.proforma && (
                      <>
                        <Typography variant="h5">Invoice</Typography>
                        <Divider />
                      </>
                    )}

                    <Grid container spacing={2}>
                      {renderSidebarMainInfo(invoice)}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={OrderDetailsStyle}>
                  <CardContent>
                    {renderSidebarFileInfo(invoice)}
                  </CardContent>
                </Card>
              </Grid>

              {invoice?.proforma && (
                <>
                  <Grid item xs={12}>
                    <Card sx={OrderDetailsStyle}>
                      <CardContent>
                        <Typography variant="h5">Proforma</Typography>
                        <Divider />
                        <Grid container spacing={2}>
                          {renderSidebarMainInfo(invoice.proforma)}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={OrderDetailsStyle}>
                      <CardContent>
                        {renderSidebarFileInfo(invoice.proforma)}
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default InvoiceForm;
