import { combineReducers } from '@reduxjs/toolkit';
import sessionReducer, { sessionApi } from '../session';
import internalLogisticsSlice from './internalLogisticsSlice';
import { default as logisticsReducer } from './logisticsSlice';
import { invoiceManagementSlice } from './invoiceManagementSlice';

export default combineReducers({
  logistics: logisticsReducer,
  internalLogistics: internalLogisticsSlice,
  invoiceManagement: invoiceManagementSlice,
  session: sessionReducer,
  [sessionApi.reducerPath]: sessionApi.reducer
});
