import {
  Box, Card, Grid
} from '@mui/material';
import React from 'react';
import { Filters } from '../../../../../Logistics/style';
import ArchiveButton from '../ArchiveButton';

const PendingPaymentForm = ({ invoiceId, onSubmit }) => (
  <Card sx={{ padding: '1rem 1rem 1rem 1rem' }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
          <ArchiveButton invoiceId={invoiceId} onSubmit={onSubmit} />
        </Box>
      </Grid>
    </Grid>
  </Card>
);

export default PendingPaymentForm;
