import {
  Box, Card,
  Container,
  Divider,
  Grid,
  Typography, useMediaQuery
} from '@mui/material';
import React from 'react';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import LinkButton from '../../../components/LinkButton';
import { CAPTAIN, CO_PILOTS } from '../../../constans/crew';
import { useGetTrainingFlightCrewTableQuery } from '../../../store/session';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { columnsCommanders, columnsFO } from './columns';
import {
  btnS,
  headerBtn,
  headerBtnS,
  headerBtnXs,
  tableContainer,
} from './styles';

const getPilotStatus = (pilot) => {
  let status = '';

  if (pilot?.docs_empty) {
    status = 'no-file';

    return status;
  } 
  if (pilot?.docs_expired) {
    status = 'invalid';

    return status;
  } 
  if (pilot?.docs_threshold) {
    status = 'outdated';

    return status;
  } 
  status = 'valid';

  return status;
};

const FlightCrew = () => {
  const maxWidth1000px = useMediaQuery('(max-width:1275px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const hasPermissions = usePermissions('training_module_write');

  return (
    <>
      <LinkButton path="/training-module">
        <Button title="Back"></Button>
      </LinkButton>
      <Card sx={{
        mt: 3,
        padding: '1rem',
      }}
      >
        <Box
          sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
        >
          <Box>
            <Typography variant="h3">Flight Crew</Typography>
          </Box>
          {hasPermissions
          && (
          <Box sx={{ ...(maxWidth800px && headerBtnXs) }}>
            <LinkButton path="/training-module/flight-crew/default-documents">
              <Button sx={{ ml: 2, ...(maxWidth800px && btnS) }} title="Manage Default Documents" />
            </LinkButton>
          </Box>
          )}

        </Box>

        <Divider sx={{ mt: 5, mb: 3 }} />

        <Container
          sx={tableContainer}
          style={maxWidth800px ? {
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          } : { display: 'flex', justifyContent: 'space-between', gap: '16px' }}
        >
          <Grid item xs={maxWidth800px ? 12 : 6} style={{ flex: 1 }}>
            <EditableTable
              useHook={useGetTrainingFlightCrewTableQuery}
              getRowId={(row) => row.id}
              columns={columnsCommanders}
              sortByType="ASC"
              getRowClassName={({ row }) => `row-${getPilotStatus(row)}`}
              sortByActive="pilot_name"
              hasPermissions={hasPermissions}
              tableParams={{ post: CAPTAIN }}
            />
          </Grid>
          <Grid item xs={maxWidth800px ? 12 : 6} style={{ flex: 1 }}>
            <EditableTable
              useHook={useGetTrainingFlightCrewTableQuery}
              getRowId={(row) => row.id}
              columns={columnsFO}
              sortByType="ASC"
              hasPermissions={hasPermissions}
              getRowClassName={({ row }) => `row-${getPilotStatus(row)}`}
              sortByActive="pilot_name"
              tableParams={{ post: CO_PILOTS }}
            />
          </Grid>
        </Container>
      </Card>
    </>
  );
};

export default FlightCrew;
