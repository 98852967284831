import {
  Box,
  Button, Card, CardContent, Divider, Grid
} from '@mui/material';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import React from 'react';
import { Controller } from 'react-hook-form';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import DatePicker from '../../../../form/components/DatePicker';
import FieldError from '../../../../form/components/FieldError';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import { validateDatePickerValue } from '../../../../utils/setDateValue';
import { operationsList } from './schema';
import { useMntAircraftForm } from './useMntAircraftForm';

const MntAircraft = () => {
  const {
    onSubmit,
    aircraftData,
    aircraftLoading,
    handleSubmit,
    watch,
    control,
    maxWidth768px,
    errors,
    updating,
    handleReadOds,
  } = useMntAircraftForm();

  if (aircraftLoading) {
    return <ContentPageLoader />;
  }

  return (
    <form>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            Update Aircraft /
            {' '}
            {aircraftData?.aircraft_registration}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 7}>
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Aircraft Status" required hasError={!!errors.aircraft_status}>
                    <Controller
                      name="aircraft_status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={operationsList}
                          placeholder="Aircraft Status"
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_status} />
                  </FormGroup>
                </Grid>

                {watch('aircraft_status') === 0 && (
                  <>
                    <Grid item xs={2} sm={2} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      <FormGroup label="Maintenance from" hasError={!!errors.maint_from}>
                        <Controller
                          name="maint_from"
                          control={control}
                          render={({ field: { onChange, ...req } }) => (
                            <DatePicker
                              {...req}
                              placeholderText="YYYY-MM-DD"
                              dateFormat="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown
                              onChange={(e) => {
                                onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                              }}
                              value={validateDatePickerValue(req.value)}
                            />
                          )}
                        />
                        <FieldError error={errors.maint_from} />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={2} sm={2} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      <FormGroup label="Maintenance to" hasError={!!errors.maint_to}>
                        <Controller
                          name="maint_to"
                          control={control}
                          render={({ field: { onChange, ...req } }) => (
                            <DatePicker
                              {...req}
                              placeholderText="YYYY-MM-DD"
                              dateFormat="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown
                              onChange={(e) => {
                                onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                              }}
                              value={validateDatePickerValue(req.value)}
                            />
                          )}
                        />
                        <FieldError error={errors.maint_to} />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={5} sm={5} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      <FormGroup label="Maintenance Reason" hasError={!!errors.maint_reason}>
                        <Controller
                          name="maint_reason"
                          control={control}
                          render={({ field }) => (
                            <Input
                              placeholder="Maintenance Reason"
                              {...field}
                            />
                          )}
                        />
                        <FieldError error={errors.maint_reason} />
                      </FormGroup>
                    </Grid>
                  </>
                )}
              </Grid>

              <Divider />

              <Grid container spacing={6}>

                <Grid item xs={maxWidth768px ? 12 : 4} sm={4} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Manufacturing Date" hasError={!!errors.aircraft_data_date}>
                    <Controller
                      name="aircraft_data_date"
                      control={control}
                      render={({ field: { onChange, ...req } }) => (
                        <DatePicker
                          {...req}
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          onChange={(e) => {
                            onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                          }}
                          value={validateDatePickerValue(req.value)}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_data_date} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 4} sm={4} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Aircraft Data FH" hasError={!!errors.aircraft_data_fh}>
                    <Controller
                      name="aircraft_data_fh"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Aircraft Data FH"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_data_fh} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 4} sm={4} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Aircraft Data CYC" hasError={!!errors.aircraft_data_cyc}>
                    <Controller
                      name="aircraft_data_cyc"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Aircraft Data CYC"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_data_cyc} />
                  </FormGroup>
                </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={6}>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine LH Data Date" hasError={!!errors.aircraft_engine_lh_data_date}>
                    <Controller
                      name="aircraft_engine_lh_data_date"
                      control={control}
                      render={({ field: { onChange, ...req } }) => (
                        <DatePicker
                          {...req}
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          onChange={(e) => {
                            onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                          }}
                          value={validateDatePickerValue(req.value)}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_lh_data_date} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine LH FH" hasError={!!errors.aircraft_engine_lh_data_fh}>
                    <Controller
                      name="aircraft_engine_lh_data_fh"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Engine LH FH"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_lh_data_fh} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine LH CYC" hasError={!!errors.aircraft_engine_lh_data_cyc}>
                    <Controller
                      name="aircraft_engine_lh_data_cyc"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Engine LH CYC"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_lh_data_cyc} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine LH Desciption" hasError={!!errors.aircraft_engine_lh_desc}>
                    <Controller
                      name="aircraft_engine_lh_desc"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Engine LH Desciption"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_lh_desc} />
                  </FormGroup>
                </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={6}>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine RH Data Date" hasError={!!errors.aircraft_engine_rh_data_date}>
                    <Controller
                      name="aircraft_engine_rh_data_date"
                      control={control}
                      render={({ field: { onChange, ...req } }) => (
                        <DatePicker
                          {...req}
                          placeholderText="YYYY-MM-DD"
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          onChange={(e) => {
                            onChange(e ? moment(e).format('yyyy-MM-DD') : null);
                          }}
                          value={validateDatePickerValue(req.value)}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_rh_data_date} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine RH FH" hasError={!!errors.aircraft_engine_rh_data_fh}>
                    <Controller
                      name="aircraft_engine_rh_data_fh"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Engine RH FH"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_rh_data_fh} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine RH CYC" hasError={!!errors.aircraft_engine_rh_data_cyc}>
                    <Controller
                      name="aircraft_engine_rh_data_cyc"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Engine RH CYC"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_rh_data_cyc} />
                  </FormGroup>
                </Grid>

                <Grid item xs={maxWidth768px ? 12 : 3} sm={3} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <FormGroup label="Engine RH Desciption" hasError={!!errors.aircraft_engine_rh_desc}>
                    <Controller
                      name="aircraft_engine_rh_desc"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="Engine RH Desciption"
                          {...field}
                        />
                      )}
                    />
                    <FieldError error={errors.aircraft_engine_rh_desc} />
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box sx={{
            mt: 5, display: 'flex', gap: 5, ...(maxWidth768px && { flexDirection: 'column' }) 
          }}
          >
            <Button type="submit" disabled={updating} variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
              Update
            </Button>
            <Button disabled={updating} variant="contained" size="large" onClick={handleReadOds}>
              Read Data From Ods
            </Button>
          </Box>
          
        </Grid>
      </Grid>
    </form>
  );
};

export default MntAircraft;
