import {
  object,
  string,
  number
} from 'yup';

export const defaultValues = {
  logistic_supplier_id: '',
  value: '',
  currency_id: '',
  date: null,
  description: '',
  reason: '',
};

export const schema = object().shape({
  logistic_supplier_id: number()
    .required('${label} cannot be blank')
    .label('Logistic Supplier ID'),
  value: number()
    .required('${label} cannot be blank')
    .label('Amount'),
  currency_id: number()
    .required('${label} cannot be blank')
    .label('Currency ID'),
  description: string()
    .nullable()
    .label('Description'),
  reason: string()
    .nullable()
    .label('Reason'),
});
