import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Divider, Grid, IconButton, Typography
} from '@mui/material';
import { Plus } from 'mdi-material-ui';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Button from '../../../../../../../../components/Button';
import FormGroup from '../../../../../../../../form/components/FormGroup';
import GridFormGroup from '../../../../../../../../form/components/GridFormGroup';
import Input from '../../../../../../../../form/components/Input';
import Select from '../../../../../../../../form/components/Select';
import { changeKeyToName } from '../../../../../../../../utils/getOptions';
import { GreenButton, RedButton } from '../../../../../../style';
import { defaultValues, STORAGE_ADDRESS } from './useSupplierInvoice';

const SupplierInvoiceForm = (props) => {
  const {
    errors = {},
    control,
    deliveryServices,
    partsArray = [],
    handleAppendNewParts = () => {},
    handleRemoveParts = () => {},
    isRegenerate = false,
    order = {},
    setValue = () => {},
    watch = () => {},
    supplier
  } = props;

  const setShipmentData = (data) => {
    setValue('ship_to', `Please ship the components to the following address:
${STORAGE_ADDRESS[order.storage] ?? '...'}

Please ship via:
${data}
`);
  };

  useEffect(() => {
    setShipmentData(watch('ship_via'));
  }, [watch('ship_via')]);

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h6">Credit Note Balance</Typography>
        <Typography variant="subtitle1"><b>{`${supplier?.data?.credit_note_balance_eur} EUR`}</b></Typography>
      </Grid>

      <Grid item xs={4}>
        <FormGroup label="Vendor Email" required hasError={!!errors.email}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input placeholder="Vendor Email" {...field} />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={4}>
        <FormGroup label="Ship Via" required hasError={!!errors.ship_via}>
          <Controller
            control={control}
            name="ship_via"
            render={({ field }) => (
              <Select
                options={deliveryServices}
                placeholder="Ship Via"
                {...field}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="To:" required hasError={!!errors.to}>
          <Controller
            control={control}
            name="to"
            render={({ field }) => (
              <Input
                multiline
                rows={2}
                placeholder="..."
                {...field}
              />
            )}
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup label="Ship To:" required hasError={!!errors.ship_to}>
          <Controller
            control={control}
            name="ship_to"
            render={({ field }) => (
              <Input
                multiline
                rows={12}
                placeholder="..."
                {...field}
              />
            )}
          />
        </FormGroup>

        <Divider sx={{ mt: 5, mb: 4 }} />
      </Grid>

      <Grid
        xs={12}
        container
        sx={{
          paddingLeft: isRegenerate ? '1.5rem' : '0.5rem'
        }}
      >
        {
          partsArray?.map((part, index) => (
            <Box sx={{
              mb: 5,
              boxShadow: '0 0 6px -1px #cacaca',
              borderRadius: '10px'
            }}
            >
              <Grid
                xs={12}
                sx={{
                  border: '1px solid rgba(76, 78, 100, 0.12)',
                  borderRadius: '10px',
                  width: '100%',
                  padding: '1rem 0.4rem',
                }}
              >
                <Grid sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 5,
                  paddingRight: '0.5rem'
                }}
                >
                  <Typography variant="h6">
                    Part #
                    {index + 1}
                  </Typography>

                  {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    sx={{
                      ...(RedButton),
                      fontWeight: 400,
                      border: '1px solid #bb3a3a',
                      transition: 'all .2s linear',
                      '&:hover': {
                        backgroundColor: '#fff',
                        color: '#bb3a3a'
                      }
                    }}
                    onClick={() => handleRemoveParts(index)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  )}
                </Grid>
                <Grid
                  container
                  xs={12}
                  sx={{
                    rowGap: 5
                  }}
                >
                  {
                    Object.keys(defaultValues?.parts[0])?.map((key) => (
                      <GridFormGroup
                        type={key === 'qty' ? 'number' : undefined}
                        control={control}
                        item={{
                          name: `parts.${[index]}.${key}`,
                          label: changeKeyToName(key),
                          error: errors?.parts?.[index]?.[key]
                        }}
                      />
                    ))
                  }
                </Grid>

              </Grid>

            </Box>
          ))
        }

        <Button
          title="Add Part"
          startIcon={<Plus />}
          sx={{ ...GreenButton, mt: 3 }}
          onClick={handleAppendNewParts}
        />
      </Grid>

    </>
  );
};

export default SupplierInvoiceForm;
