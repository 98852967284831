import AddIcon from '@mui/icons-material/Add';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PollIcon from '@mui/icons-material/Poll';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import TransactionDrawer from '../../../components/TransactionDrawer';
import { useGetTransactionFilesMutation, useUploadTransactionFileMutation } from '../../../store/session';
import {
  useDeleteFinanceInvoiceMutation,
  useGetFinanceInvoiceFileMutation,
  useGetFinanceInvoiceTableQuery,
  useGetInvoicesTotalQuery,
  useSearchInvoiceTransactionQuery,
  useUpdateFinanceInvoiceMutation,
  useUpdateFinanceInvoiceStatusMutation
} from '../../../store/slices/financeSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { btnS, headerBtn, headerBtnS } from '../../Invoices/style';
import { GreenButton, TextWithIcon } from '../../Logistics/style';
import CardStatistics from '../components/CardStatistics';
import CreateInvoice from './CreateInvoice/CreateInvoice';
import { columns } from './column';

const GenerateInvoice = () => {
  const [openFile, { isLoading: pdfLoading }] = useGetFinanceInvoiceFileMutation();
  const [updateStatus, { isLoading: updating }] = useUpdateFinanceInvoiceStatusMutation();
  const [refetchTable, setRefetchTable] = useState(0);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [search, setSearch] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const hasPermission = usePermissions('finance_generate_invoice_write');
  const maxWidth1000px = useMediaQuery('(max-width:1000px)');
  const maxWidth800px = useMediaQuery('(max-width:800px)');
  const { data: invoicesTotal, refetch, isSuccess } = useGetInvoicesTotalQuery();
  const [refresh, setRefresh] = useState(false);
  const [transactionState, setTransactionState] = useState({
    isOpen: false,
    transaction_id: null,
    item_id: null,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [getTransactionFiles, { isLoading: isLoadingTransactionPortalFiles }] = useGetTransactionFilesMutation();
  const [uploadTransactionFiles, { isLoading: isAttachTransactionLoading }] = useUploadTransactionFileMutation();

  const handleUpdatePaid = () => {
    setRefetchTable(refetchTable + 1);
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handlePreviewFile = async (invoiceId) => {
    if (pdfLoading) return;
    const toastId = toast.loading('Opening...');

    await openFile(invoiceId).then((res) => {
      if (!res?.errors) {
        toast.success('Success!', {
          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleToggleInvoiceStatus = async (invoiceId) => {
    if (updating || !hasPermission) return;
    const toastId = toast.loading('Loading...');

    await updateStatus(invoiceId).then((res) => {
      if (!res?.errors) {
        toast.success('Success!', {
          id: toastId,
        });

        refetch();
        handleUpdatePaid();
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleTotalPrice = (data) => {
    if (data) {
      setCurrentInvoice({
        invoice_number: data?.invoice_number,
        invoice_status: data?.invoice_status,
        id: data?.id,
        invoice_type: data?.invoice_type,
        invoice_data: data?.invoice_data,
        currency_id: data?.currency_id
      });
      handleOpenForm();
    } else {
      setCurrentInvoice(null);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    handleTotalPrice(null);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };
  const handleOpenInvoiceTransaction = (data) => {
    setTransactionState({
      isOpen: true,
      transaction_id: data.transaction_id,
      item_id: data.item_id
    });
  };
  const getTransactionFileList = async (id, callback) => {
    const res = await getTransactionFiles(id);

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.');
      } else {
        toast.error('Unable to get file list.');
      }

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleTransactionPortalFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    setTransactionState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
    // if (id) {
    //   getTransactionFileList(id, (list) => setTransactionState(prev => ({
    //     ...prev,
    //     isOpen,
    //     type,
    //     currentId: id,
    //     fileList: setFilesName(list),
    //     name,
    //     currentName
    //   })));
    // }
  };

  const updateTransactionFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await uploadTransactionFiles({ id, data: formData });

    if (res?.error) {
      if (res.error.status === 403) {
        toast.error('You do not have permission to perform this action.', {
          id: toastId,
        });
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        {invoicesTotal && (
          <Grid container spacing={6}>
            {invoicesTotal?.total && (
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Total" icon={<PollIcon />} stats={invoicesTotal?.total || 0} color="info" />
            </Grid>
            )}
            {invoicesTotal?.paid && (
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Paid" icon={<CreditScoreIcon />} stats={invoicesTotal?.paid || 0} />
            </Grid>
            )}
            {invoicesTotal.unpaid && (
            <Grid item xs={12} md={3} sm={6}>
              <CardStatistics title="Unpaid" icon={<CreditCardOffIcon />} stats={invoicesTotal.unpaid || 0} color="error" />
            </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Card sx={{
          mt: 3,
          padding: '1rem'
        }}
        >
          <Box
            sx={{ ...headerBtn, ...(maxWidth1000px && headerBtnS) }}
          >
            <Typography noWrap variant="h4" sx={TextWithIcon}>
              <ReceiptIcon />
              Invoices
            </Typography>

            <Box />
            
            <Box sx={{ justifySelf: 'end' }}>
              <Button
                disabled={!hasPermission}
                endIcon={<AddIcon />}
                sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS), ...GreenButton }}
                title="Generate new  Invoice"
                onClick={() => handleOpenForm()}
              />
            </Box>
           
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Box sx={{
            p: 5,
            pb: 3,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <TextField
                size="small"
                sx={{
                  mr: 6, mb: 2, minWidth: '300px', maxWidth: '300px'
                }}
                placeholder="Search..."
                onChange={debounce((e) => setSearch(e.target.value), 400)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Divider />
          <EditableTable
            useHookUpdate={useUpdateFinanceInvoiceMutation}
            useHook={useGetFinanceInvoiceTableQuery}
            useHookDelete={useDeleteFinanceInvoiceMutation}
            columns={columns}
            sortByType="DESC"
            sortByActive="id"
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id}
            hasPermission={hasPermission}
            refetchTable={refetchTable}
            handlePreviewFile={(invoiceId) => handlePreviewFile(invoiceId)}
            handleTotalPrice={handleTotalPrice}
            handleToggleInvoiceStatus={(invoiceId) => handleToggleInvoiceStatus(invoiceId)}
            handleOpenInvoiceTransaction={handleOpenInvoiceTransaction}
            handleTransactionPortalFileDrawer={handleTransactionPortalFileDrawer}
            refetchCallback={() => {
              if (isSuccess) {
                try {
                  refetch();
                } catch (err) {}
              }
            }}
            tableParams={{
              search,
              refresh
            }}
            style={{
              '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
            }}
          />
        </Card>
      </Grid>

      <CreateInvoice open={openForm} onClose={handleCloseForm} refresh={handleUpdatePaid} currentInvoice={currentInvoice} />
      <TransactionDrawer
        open={transactionState.isOpen}
        onClose={() => handleTransactionPortalFileDrawer(false)}
        uploadFile={(file) => updateTransactionFiles(transactionState.currentId, file, transactionState.type, () => handleTransactionPortalFileDrawer(true, transactionState.currentId, transactionState.type, transactionState.name, transactionState.currentName))}
        handleRefresh={handleRefresh}
        itemData={transactionState}
        itemName={transactionState.currentName}
        itemsList={transactionState.fileList}
        hasPermissions={hasPermission}
        isLoadingFiles={isLoadingTransactionPortalFiles}
        deleteFileCallback={handleRefresh}
        isAttachLoading={isAttachTransactionLoading}
        useUpdateMutation={useUpdateFinanceInvoiceMutation}
        useSearchTransaction={useSearchInvoiceTransactionQuery}
        maxWidth800px={maxWidth800px}
      />
    </Grid>
  );
};

export default GenerateInvoice;
